import React, { Component } from 'react';
import config from './config';
import history from './history/history';
import NavRoutes from './component/nav_routes/nav_routes';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Session } from './helper/session';
import { LicenseManager } from "@ag-grid-enterprise/core";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { Message } from "semantic-ui-react";

import Avatar from '@material-ui/core/Avatar';
import { Dropdown } from 'semantic-ui-react'
import { Utils } from './helper/utils';

const publicIp = require('public-ip');

class App extends Component {
  constructor(props) {
    super(props);
    //LicenseManager.setLicenseKey("CompanyName=Tor Investment Management,LicensedApplication=DDS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-016467,ExpiryDate=22_September_2022_[v2]_MTY2MzgwMTIwMDAwMA==4ae34e7373639d6c5e92a215d5e373b0");
    LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-045460 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Tor Investment Management )_is_granted_a_( Single Application )_Developer_License_for_the_application_( DDS )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( DDS )_need_to_be_licensed___( DDS )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 21 September 2024 )____[v2]_MTcyNjg3MzIwMDAwMA==cb1b566e378c4f55166d73f9a205d0e8");

    this.mounted = false;
    this.state = {
      navbarToggler: 'navbar-toggler',
      ariaExpanded: 'false',
      navbarCollapse: 'navbar-collapse collapse',
      user: Session.getItem(config.token) ? JSON.parse(Session.getItem(config.token)) : null,
      hasError: false,
      ipAddress: '127.0.0.1',
      errorList: [],
      currentPage: window.location.pathname
    }

    this.handleNavBarToggler = this.handleNavBarToggler.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleGoto = this.handleGoto.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    this.unstable_handleError = this.unstable_handleError(this);
    this.getPublicIP = this.getPublicIP.bind(this);
    this.renderApp = this.renderApp.bind(this);
    this.stringAvatar = this.stringAvatar.bind(this);
    this.loggedUser = JSON.parse(Session.getItem(config.token));
  }

  updateState() {
    if (this.mounted) {
      let user = Session.getItem(config.token);
      if (user && !user.user) {
        user = JSON.parse(user);
      }

      this.setState({ user: user, currentPage: window.location.pathname });
    }
  }

  handleNavBarToggler() {
    if (this.mounted) {
      this.setState({
        navbarToggler: this.state.navbarToggler === 'navbar-toggler' ? 'navbar-toggler collapsed' : 'navbar-toggler',
        ariaExpanded: this.state.ariaExpanded === 'false' ? 'true' : 'false',
        navbarCollapse: this.state.navbarCollapse === 'navbar-collapse collapse' ? 'navbar-collapse collapse show' : 'navbar-collapse collapse'
      });
    }
  }

  handleGoto(page) {
    this.setState({ currentPage: page });
    history.push(page);
    //this.context.history.push('/path');
    window.location.pathname = page;
  }

  componentDidMount() {
    this.mounted = true;
    if (this.state.ipAddress !== '82.150.104.2' && this.state.ipAddress !== '82.150.107.2') {
      this.getPublicIP();
    }
  }

  getPublicIP = async () => {
    var ipAddress = await publicIp.v4();
    this.setState({ ipAddress: ipAddress });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, errorList: [error.message] });
  }

  unstable_handleError(e) {
    this.setState({ hasError: true, errorList: [e] })
  }

  closeMessage() {
    this.setState({ hasError: false, errorList: [] });
  }

  render() {
    return this.renderApp();
  }

  stringAvatar(name) {
    return {
      sx: {
        bgcolor: 'white',
        color: '#09253D'
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  renderApp() {
    this.loggedUser = JSON.parse(Session.getItem(config.token));
    //const tet = Utils.getArraysIntersection(['Tester'], this.loggedUser.userRoles);
    const { user, errorList, hasError, navbarToggler, ariaExpanded, navbarCollapse } = this.state;
    return (
      <div style={{ gridTemplateRows: 'auto auto 1fr auto', display: 'grid', height: '100%', width: '100%', borderTop: "solid 1px white", position: 'relative' }}>
        <div>
          <nav className="navbar navbar-expand-lg navbar-dark nav-dark">
            <span className="navbar-brand">TOR</span>
            <button className={navbarToggler} type="button" data-toggle="collapse"
              data-target="#navbarColor01" aria-controls="navbarColor01"
              aria-expanded={ariaExpanded} aria-label="Toggle navigation" onClick={this.handleNavBarToggler}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={navbarCollapse} id="navbarColor01">
              <ul className="navbar-nav mr-auto">
                <Dropdown text='Analyst Input' className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'Ops', 'Finance', 'Compliance', 'Legal'], this.loggedUser.userRoles).length > 0 ? 'link item ml10' : 'hidemenu'}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handleGoto('/dss')}>DSS</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/coinvestment')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Tester', 'IR', 'Analyst', 'Approver', 'Trader', 'Partner', 'PM'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Co-Investments</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/dealPipelineDashboard')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'FinanceAdmin'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Deal Pipeline</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/dealQuestionaire')}>Deal Questionaire</Dropdown.Item>
                    <Dropdown.Divider className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Partner', 'Legal', 'Analyst'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'} />
                    <Dropdown.Item onClick={() => this.handleGoto('/icApproval')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Partner', 'Legal', 'Analyst', 'Compliance'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>IC Approval</Dropdown.Item>
                    <Dropdown.Divider className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'} />
                    <Dropdown.Item onClick={() => this.handleGoto('/assetCommentaryInput')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Asset Management Commentary</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/assetCommentaryReview')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Tester'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Asset Management Commentary Review</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Reports' className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Approver', 'Trader', 'IR', 'Partner', 'PM', 'Analyst', 'Tester', 'FinanceAdmin'], this.loggedUser.userRoles).length > 0 ? 'link item ml10' : 'hidemenu'}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handleGoto('/portfolio')}>Portfolio Dashboard</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => this.handleGoto('/deals')}>Deal Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/dealQuestionaireSummary')}>Deal Questionaire Summary</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => this.handleGoto('/liquidity')}>Liquidity Dashboard</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Portfolio Management' className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Trader', 'Partner', 'Finance', 'Ops'], this.loggedUser.userRoles).length > 0 ? 'link item ml10' : 'hidemenu'}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handleGoto('/markingTool')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Trader', 'Partner', 'Finance'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Marking Tool</Dropdown.Item>
                    <Dropdown.Divider className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Trader', 'Partner', 'Finance'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'} />
                    {/* <Dropdown.Item onClick={() => this.handleGoto('/pnl')} className= {this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Trader', 'Partner', 'Finance' ], this.loggedUser.userRoles).length > 0 ? '': 'hidemenu'}>Real-Time Dashboard</Dropdown.Item> */}
                    <Dropdown.Item onClick={() => this.handleGoto('/publicpnl')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Trader', 'Partner', 'Finance'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Public PnL Dashboard</Dropdown.Item>
                    <Dropdown.Divider className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Partner', 'Legal'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'} />
                    <Dropdown.Item onClick={() => this.handleGoto('/covenantDashboard')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Partner', 'Legal', 'Ops'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Covenant Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/covenantNew')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Partner', 'Legal', 'Ops'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Covenant New</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/covenants')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Partner', 'Legal', 'Ops'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Covenant Entry</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Compliance' className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Tester', 'Compliance'], this.loggedUser.userRoles).length > 0 ? 'link item ml10' : 'hidemenu'}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handleGoto('/restrictedList')} >Restricted List</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Dropdown text='Questionnare' className= {'link item ml10'}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handleGoto('/diversityquestionnare')}>Diversity & Inclusion</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Dropdown text='Miscellaneous' className='link item ml10'>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.handleGoto('/taskMasterDashboard')}>Task Master Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/taskInstanceDashboard')}>Task Instance Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/housing')}>Housing</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/housingSummary')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Tester', 'FinanceAdmin'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>Housing Summary</Dropdown.Item>
                    <Dropdown.Divider className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Tester'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'} />
                    <Dropdown.Item onClick={() => this.handleGoto('/changepassword')}>Change Password</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleGoto('/userlog')} className={this?.loggedUser?.userRoles && Utils.getArraysIntersection(['Risk', 'Tester'], this.loggedUser.userRoles).length > 0 ? '' : 'hidemenu'}>User Log</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>

              {/* <ul className="navbar-nav mr-auto">
                  <li className={currentPage && currentPage.startsWith('/dss') ? "nav-item active" : "nav-item"}>
                    <span className="nav-link" onClick={() => this.handleGoto('/dss')}>DSS</span>
                  </li>
                  <li className={currentPage && currentPage.startsWith('/portfolio') ? "nav-item active" : "nav-item"}>
                    <span className="nav-link" onClick={() => this.handleGoto('/portfolio')}>Portfolio <span className="sr-only">(current)</span></span>
                  </li>
                  <li className={currentPage && currentPage.startsWith('/deals') ? "nav-item active" : "nav-item"}>
                    <span className="nav-link" onClick={() => this.handleGoto('/deals')}>Deals</span>
                  </li>
                  <li className={currentPage && currentPage.startsWith('/icApproval') ? "nav-item active" : "nav-item"}>
                    <span className="nav-link" onClick={() => this.handleGoto('/icApproval')}>IC Approval</span>
                  </li>
                  <li className={currentPage && currentPage.startsWith('/markingTool') ? "nav-item active" : "nav-item"}>
                    <span className="nav-link" onClick={() => this.handleGoto('/markingTool')}>Marking Tool</span>
                  </li> */}
              {/*
                    <li className={ currentPage && currentPage.startsWith('/housing') ? "nav-item active" : "nav-item" }>
                      <span className="nav-link" onClick={() => this.handleGoto('/housing')}>Housing</span>
                    </li>
                    <li className={ currentPage && currentPage.startsWith('/assetCommentaryInput') ? "nav-item active" : "nav-item" }>
                      <span className="nav-link" onClick={() => this.handleGoto('/assetCommentaryInput')}>Deal Updates</span>
                    </li>*/
              }

              {/* <li className={currentPage && currentPage.startsWith('/pnl') ? "nav-item active" : "nav-item"}>
                    <span className="nav-link" onClick={() => this.handleGoto('/pnl')}>Pnl</span>
                  </li>
                  <li className={currentPage && currentPage.startsWith('/changepassword') ? "nav-item active" : "nav-item"}>
                    <span className="nav-link" onClick={() => this.handleGoto('/changepassword')}>Change Password</span>
                  </li>
                </ul> */}
              <form className="form-inline">
                <button type="button" id="userDisplay" onClick={this.updateState} style={{ display: 'none' }}>Dummy</button>
                {user && user.user ? <Avatar onClick={() => this.handleGoto('/userprofile')} style={{ marginRight: "10px", cursor: "pointer" }} {...this.stringAvatar(user.user.name)} /> : null}
                <span className="nav-link login" onClick={() => this.handleGoto('/login')}>{user ? 'Sign Out' : 'Sign In'}</span>
              </form>
            </div>
          </nav>
        </div>
        <div>
          {
            hasError ?
              <Message
                error
                header="Unhandled exception :"
                list={errorList}
                onDismiss={this.closeMessage}
              />
              : null
          }
        </div>
        <div style={{ overflow: 'hidden' }}>
          <NavRoutes history={history}></NavRoutes>
        </div>
        <div style={{ textAlign: 'center', paddingTop: "0px", paddingBottom: "0px", color: '#fff', height: 'auto', backgroundColor: '#09253D' }}>
          <FontAwesomeIcon icon={faCopyright} /> Tor Investment Management 2023
        </div>
      </div>
    );
  }
}

export default App;