/**<import>****************************************************************************************************/
import { Dropdown } from 'semantic-ui-react';
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Prompt } from "react-router-dom"; 
import Modal from 'react-awesome-modal';
import { housingBALService } from '../../bal/housing.bal';
import { s3BALService } from '../../bal/s3.bal';
import fileDownload from 'js-file-download'
// Our components
import config from '../../config';
import { Session } from '../../helper/session';

import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";

class Housing extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        const user = JSON.parse(Session.getItem(config.token));
        let newList = [
            //{key: '2021', value: '2021', text: '2021-2022'},
            {key: '2023', value: '2023', text: '2023-2024'}
        ];
        this.state = { 
            rowData: [],
            isShowLoad: false, 
            isShowGrid: true, 
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            user: user,
            userId: user.user.id,
            num: -1,
            open:false,
            fyList : newList,
            fy : '2023',
            currencyList : [{key: 'HKD', value: 'HKD', text: 'HKD'} ,{key: 'SGD', value: 'SGD', text: 'SGD'} ,{key: 'USD', value: 'USD', text: 'USD'}],
            currency: 'HKD',
            //forDate : moment(new Date()).format("YYYY-MM-DD"),
            maxDate: null
        };
        this.refresh = this.refresh.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleDropDownChangeDefaultCurrency = this.handleDropDownChangeDefaultCurrency.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        //getFormatted = getFormatted(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePanel = this.togglePanel.bind(this); 
        this.cellValueChanged = this.cellValueChanged.bind(this); 
        this.save = this.save.bind(this);
        this.setFile = this.setFile.bind(this);
        this.upload = this.upload.bind(this);
        this.getS3Path = this.getS3Path.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.download = this.download.bind(this);
        this.remove = this.remove.bind(this);
    }

    download(fileFullPath, fileName) {
        s3BALService.downloadFile(fileFullPath).then(data => {
            fileDownload(data, fileName.substring(fileName.indexOf('_') + 1));
        }, error => {
            this.setState({
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while downloading the attached file:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Attached file could not be downloaded, please contact support!'
            });
        });
    }

    remove(fileFullPath, fileName) { 
        s3BALService.deleteFile({fileName : fileFullPath, type: 'housing'}).then(data => {
            this.getFiles();
        }, error => {
            this.setState({ 
                showMessage: true, 
                errorList: [error], 
                messageHeader : "Error while removing the attached file:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'save',
                modalHeading: 'Attached file could not be removed, please contact support!'
            });
        });
    }
    
    getFiles = async () => {
        await s3BALService.getFiles({ path: this.getS3Path().replace(/~/g, '/') }).then(data => {
            this.setState({files : data.files});
        });
    }

    getS3Path() {
        const { fy, user} = this.state;
        return `housing~${fy}~${user.user.username}`;
    }

    upload(e) {
        const { file, fy, userId} = this.state;
        if(file) {
            const path = this.getS3Path();
            s3BALService.uploadFile({file: file, fy: fy, userId:userId, path: path }).then(data => {
                    this.setState({
                        showMessage: false, 
                        file: null,
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'File uploaded successfully!',
                        open: false
                    });
                    this.getFiles();
                }, error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : "Error while uploading the data :",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'save',
                        modalHeading: 'File could not be uploaded, please contact support!'
                    });
                }
            );
        }
    }

    setFile(e) {    
        this.setState({
            file: e.target.files[0]
        });
    }

    componentDidMount() { 
        this.mounted = true;
        this.setState({
            fy: '2023'
        }, () => {
            this.getFiles();
        })
        if (this.mounted) { 
        }
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    togglePanel(e) {
        this.setState({ open: !this.state.open });
    }

    refresh(e, modalVisible = false) {
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({ modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.setState({ modalVisible: false});
        } else {
            this.setState({ modalVisible: false});
        }
    }

    getFormatted = (value) =>  {
        return value && !isNaN(value) && parseFloat(value) ? parseFloat(value).toFixed( Number.isInteger(value) ? 0 : 2) : value;
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }
    

    handleDropDownChange(e, { name, value }) {
        this.setState({ 
            [name]: value             
        });
    }
    

    handleDropDownChangeDefaultCurrency(e, { name, value }) {
        this.setState({ 
            [name]: value 
        });
    }

    render() {
        if(this.state.redirectTo)
            return <Redirect to={ this.state.redirectTo } />;
        return this.getPage();
    }

    cellValueChanged(e) {
        if(e && e.data) {
            let col1 = Object.keys(e.data).filter(p => p.startsWith("col001_"));
            let data = this.state.gridData.filter(p=>p['Investment Code'] === e.data['Investment Code'] && p.instrument_id !== e.data.instrument_id);

            data = data.map(row => {
                return {
                    ...row,
                    [col1] : e.data[col1],
                    isBloomberg : e.data.isBloomberg
                }
            })

            this.setState({
                GridRowsToUpdate: data
            });
        }
    }

    save = async (rowData) => {
        let housingRows = rowData.map(p=>p.data);
        housingRows = housingRows.map(p=> {return {...p, fyStart:this.state.fy}});

        console.log(housingRows);

        housingBALService.save(housingRows).then(
            data => {
                    this.setState({
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saved housing data successfully!',
                        open: false ,
                        gridData: data
                    });
                }
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : `Error while saving housing:`,
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saving housing data failed!'
                    }); 
                    console.log(error);
            });
    }

    getPage() {
        const { newDeal,includeDate, fyList, fy, files, existingDeal, modalHeading, isShowLoad, isShowGrid, showMessage, messageHeader, errorList } = this.state;
        return (
            <div style={{ gridTemplateRows: 'auto auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    showMessage ?
                        <ToastMessage
                            header={messageHeader}
                            errorList={errorList}
                            closeMessage={this.closeMessage}
                        />:<div></div>
                }   
                    <div  >
                        <div style={{ float: "Left", paddingLeft:"10px"  }} ><h4><u>Housing</u></h4></div>
                              
                        {/*<div style={{ float: "right", cursor: "pointer", paddingRight:"10px", paddingLeft:"10px", paddingTop: '10px' }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>*/}
                                               
                        <div style={{ float: "right" , cursor: "pointer", minWidth: "200px" , paddingLeft:"10px"}}>
                            <Dropdown
                                placeholder='Financial Year'
                                name="fy"
                                fluid
                                search
                                selection
                                onChange={this.handleDropDownChange}
                                options={fyList}
                                value={fy}
                                className={'form-control'}
                            />
                        </div>
                        <div style={{ float: "right", paddingLeft:"10px", paddingTop: '10px' }}>
                            Financial Year
                        </div>
                        <div style={{ float: "right", paddingLeft:"10px", paddingTop: '10px' }}>
                            Currency : <b>HKD</b>
                        </div>   
                    </div>
                    <div style={{ overflowY: 'auto' ,  width: '100%' }}>
                        <TorGrid
                            gridTitle=''
                            sideBar='none'
                            gridIdentityColumn="id"
                            identitySeed={1000000000}
                            showAdd={true}
                            setAgGridAPI = {this.setAgGridAPI} 
                            groupDefaultExpanded = {-1}
                            groupIncludeTotalFooter = {false}
                            guikey = "housing"
                            isHideDateInputs={true}
                            isHideSaveGridLayout={true}
                            query={ `exec [sp_get_housing] ${this.state.userId}, ${this.state.fy}`}
                            includeDate = {includeDate}
                            suppressExpandablePivotGroups={true}
                            className="ag-theme-alpine"
                            rowGroupPanelShow="none"
                            enableFilter={false}
                            groupBy={this.props.GroupBy}
                            domLayout="autoHeight"
                            groupIncludeFooter={true}
                            defaultColDef={
                                {
                                    sortable: false
                                    , filter: false
                                    , floatingFilter: false
                                    , enableRowGroup: true
                                    , resizable: true
                                    , suppressSizeToFit: false
                                    , flex: 1
                                }
                            } 
                            singleClickEdit = {true}
                            isShowLoad = {isShowLoad}
                            isShowGrid = {isShowGrid}
                            cellValueChanged = {this.cellValueChanged}
                            save = {this.save}
                            showSave = {true}
                        />
                    </div>
                    <div   style={{  overflowX: 'auto', overflowY: 'auto', height: 'auto', minHeight:'200px', padding:'20px 20px 20px 20px'}}>
                        <div  >
                            <div style={{ float: "left", paddingDown: "15px", paddingRight: "7px" }} >
                                Select file to upload : <input type="file" onChange={this.setFile} /><br/>
                            </div>
                            <div style={{ float: "left", paddingDown: "15px", paddingRight: "50px" }} onClick={this.upload}>
                                <button key='uploadButton' className="blueB-no-margin">Upload</button> 
                            </div>
                        </div>
                        <div style={{ display: 'grid'}}>
                                {files && files.length > 0 && <div style={{ borderTop:'none', border: '1px solid black', width:"964px", float: "left", paddingRight: "55px"}}><h2>Uploaded Files:</h2></div>}
                                {files &&
                                files.map((file, index) => ( 
                                    <div style={{ height:'43px'}}>
                                        <div style={{borderLeft: '1px solid black', borderBottom: '1px solid black', float: "left",  paddingRight: "15px", width:"600px" }}>
                                            <div style={{ paddingLeft:'5px', width:"500px", height:'42px' }}>
                                                <h3>{file.fileName.substring(file.fileName.indexOf('_') + 1)}</h3>
                                            </div>
                                        </div>
                                        <div style={{ paddingLeft:'5px', paddingTop:'2px', paddingBottom: "2px", height:'100%', borderLeft: '1px solid black', borderBottom: '1px solid black', float: "left", cursor: "pointer" }} onClick={() => this.download(file.fileFullPath, file.fileName)}>
                                            <button key='downloadButton' className="blueB-no-margin">Download</button>
                                        </div>
                                        <div style={{ paddingLeft:'5px', paddingTop:'2px', paddingBottom: "2px", height:'100%', borderRight: '1px solid black', borderLeft: '1px solid black', borderBottom: '1px solid black', float: "left" }} onClick={() => this.remove(file.fileFullPath)}>
                                            <button key='removeButton' className="blueB-no-margin">Remove</button>
                                        </div> 
                                    </div> 
                                ))}
                        </div>
                </div> 
                <Prompt
                    when={this.state.isShowLoad === false && JSON.stringify(existingDeal) !== JSON.stringify(newDeal) }
                    message={location => 'Do you want to discard changes?'}
                />
                <div>
                    <section>
                        {
                            this.state.modalType === 'close'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="graybtnDes mt100" onClick={this.closeModal}>Close</button>
                                    </div>
                                </Modal>
                                :
                                this.state.modalType === 'saveChanges'
                                ?
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                                :
                                <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                    <div className="p20 centerElem" style= {{width: "420px", height: "220px"}}>
                                        <h1>{modalHeading}</h1>
                                        <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                        <button className="graybtnDes mt100 ml20" onClick={this.closeModal}>No</button>
                                        <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                    </div>
                                </Modal>
                        }
                    </section>
                </div>
            </div>
        );
    }
}

export default Housing;