import './../../App.css'
import ModalForm from '../common/modalForm'
import { userBALService } from '../../bal/user.bal'
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import ToastMessage from "../deals/components/toastMessage";
import { Prompt } from "react-router-dom";
import { taskInstanceBALService } from '../../bal/taskInstance.bal';
import { taskDetailBALService } from '../../bal/taskDetail.bal';
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faHome } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import history from '../../history/history';
import JoditEditor from "jodit-react";
import { dealBALService } from '../../bal/deal.bal';
import { Utils } from '../../helper/utils';

class TaskInstanceNew extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;

        const taskInstance = this.props?.location?.state?.data;

        this.homePath = '/taskInstanceDashboard';
        this.state = {
            ...userBALService.getUserDetails(),
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "",
            showMessage: false,
            taskDetailList: null,
            taskEntityList: null,
            taskStatusList: null,
            taskFrequencyList: null,
            taskJurisdictionRegulatorList: null,
            taskExternalServiceProviderList: null,
            userList: null,
            existingTaskInstance : taskInstance ?? {
                id:null, 
                forDate:null, 
                taskDetailId:null, 
                hasSoftDeadline:null, 
                softDeadline:null, 
                hardDeadline:null, 
                statusId:null,
                completionDate:null,
                remark:null,
                softDeadlineDays:null,
                hardDeadlineDays:null,
                entityId:null,
                jurisdictionId:null,
                name:null,
                frequencyId:null,
                externalId:null,
                externalContactName:null,
                primaryUserId:null,
                secondaryUserId:null,
                tertiaryUserId:null,
                link:null,
                remarkMaster:null,
                endDate:null
            },
            newTaskInstance : taskInstance ?? {
                id:null, 
                forDate:null, 
                taskDetailId:null, 
                hasSoftDeadline:null, 
                softDeadline:null, 
                hardDeadline:null, 
                statusId:null,
                completionDate:null,
                remark:null,
                softDeadlineDays:null,
                hardDeadlineDays:null,
                entityId:null,
                jurisdictionId:null,
                name:null,
                frequencyId:null,
                externalId:null,
                externalContactName:null,
                primaryUserId:null,
                secondaryUserId:null,
                tertiaryUserId:null,
                link:null,
                remarkMaster:null,
                endDate:null
            },
            joditConfig: {
                readonly: false // all options from https://xdsoft.net/jodit/doc/,
            }
        };
        this.render = this.render.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.getPage = this.getPage.bind(this);
        this.save = this.save.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleHome = this.handleHome.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.goHome = this.goHome.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChangeTextBox = this.handleChangeTextBox.bind(this);
        this.handleChangeNew = this.handleChangeNew.bind(this);
        this.handleDropDownChangeName = this.handleDropDownChangeName.bind(this);
        this.populateDropDownLists();
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
           this.setState({isOpenGrid: true, modalVisible: false});
        } else if (this.state.modalHandler === 'save') {
            this.save(null, false);
            this.setState({isOpenGrid: true, modalVisible: false});
        } else {
            this.setState({isOpenGrid: true, modalVisible: false});
        }
    }

    goHome() {
        history.push(this.homePath);
        window.location.pathname = this.homePath;
    }

    handleHome() {
        const { existingTaskInstance, newTaskInstance } = this.state;
        if(JSON.stringify(existingTaskInstance) !== JSON.stringify(newTaskInstance)) {
            this.setState({
                modalVisible: true,
                modalType: 'saveChanges',
                modalHandler: 'discard',
                modalHeading: 'Do you want to discard changes?'
            })
        } else {
            this.goHome();
        }
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [], messageHeader: "" });
    }
    
    save() {
        const { newTaskInstance } = this.state;
        //console.log(newTaskInstance); 

        taskInstanceBALService.save(newTaskInstance).then( 
            data => {
                    //console.log(data);
                    this.setState({
                        newTaskInstance: data,
                        existingTaskInstance: data,
                        showMessage: false, 
                        errorList: null, 
                        messageHeader : "",
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saved data successfully!',
                        open: false,
                        deal:null
                    } ); 
                } 
                , error => {
                    this.setState({ 
                        showMessage: true, 
                        errorList: [error], 
                        messageHeader : `Error while saving Task Instance :`,
                        modalVisible: true,
                        modalType: 'close',
                        modalHandler: 'close',
                        modalHeading: 'Saving data failed!'
                    }); 
                    console.log(error);
            });
    }  

    populateDropDownLists() {        
        dealBALService.getAllSettings().then(data => {
            const taskEntityList = Utils.getSettingByName(data, "TaskEntity").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const taskStatusList = Utils.getSettingByName(data, "TaskStatus").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const taskJurisdictionRegulatorList = Utils.getSettingByName(data, "TaskJurisdictionRegulator").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const taskFrequencyList = Utils.getSettingByName(data, "TaskFrequency").map(item => { return { key: item.value, value: item.id, text: item.value } });
            const taskExternalServiceProviderList = Utils.getSettingByName(data, "TaskExternalProvider").map(item => { return { key: item.value, value: item.id, text: item.value } });

            
            taskDetailBALService.getRowData().then(taskDetails => {
                let taskNameList = [];
                if(taskDetails && taskDetails.length > 0) {
                    taskNameList = taskDetails.map(item => { return { key: item.name, value: item.id, text: item.name } }); 
                }

                userBALService.getAll().then(users=> {
                    //userList
                    const userList = users.map(item => { return { key: item.name, value: item.id, text: item.name } });

                    this.setState({
                        userList: userList,
                        settingList: data,
                        taskNameList: taskNameList,
                        taskDetailList : taskDetails,
                        taskEntityList: taskEntityList,
                        taskStatusList: taskStatusList,
                        taskJurisdictionRegulatorList: taskJurisdictionRegulatorList,
                        taskFrequencyList: taskFrequencyList,
                        taskExternalServiceProviderList: taskExternalServiceProviderList
                    });
                }); 
            }, error => {
                this.setState({ 
                    showMessage: true, 
                    errorList: [error], 
                    messageHeader : `Error while retrieving Task Master...`,
                    modalVisible: true,
                    modalType: 'close',
                    modalHandler: 'close',
                    modalHeading: 'Error while retrieving Task Master!'
                }); 
                console.log(error);
            });

        }, error => { console.log(error); });
    }

    handleDropDownChange(e, { name, value }) {
        this.handleChangeNew(name, value);
    }

    handleDropDownChangeName(e, { name, value }) {
        const { taskDetailList } = this.state;
        let taskDetail = taskDetailList.filter(p => p.id === value)[0];
        const taskDetailId = taskDetail.id
        taskDetail = { ...taskDetail, taskDetailId : taskDetailId, id : null, [name]: value };
        this.setState({ 
            newTaskInstance: { ...taskDetail },
            existingTaskInstance: { ...taskDetail }
        });
    }

    handleChangeNew(name, value) {
        this.setState({ newTaskInstance: { ...this.state.newTaskInstance, [name]: value  }
        });
    }

    handleChangeTextBox(e) {
        const { name, value } = e.target; 
        this.handleChangeNew(name, value);
    }

    handleChangeCheckbox(e) {
        var checked = e.target.checked;
        this.handleChangeNew(e.target.name, checked);
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    getPage() {
        const { joditConfig, newTaskInstance, existingTaskInstance, modalType, modalVisible, modalHeading, taskNameList, taskEntityList, taskStatusList, taskFrequencyList, taskJurisdictionRegulatorList, taskExternalServiceProviderList, userList } = this.state;
        const { id, forDate, taskDetailId, hasSoftDeadline, softDeadline, hardDeadline, statusId, completionDate, remarkInstance, softDeadlineDays, hardDeadlineDays, frequencyId, entityId, jurisdictionId, externalId, externalContactName, primaryUserId, secondaryUserId, tertiaryUserId, link, remarkMaster, endDate } = newTaskInstance
        const isNew = id=== null;
        return (
            <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                {
                    this.state.showMessage ?
                        <ToastMessage
                            header={this.state.messageHeader}
                            errorList={this.state.errorList}
                            closeMessage={this.closeMessage}
                        /> : <div></div>
                }  
                <div style={{ gridTemplateRows: 'auto auto 1fr', display: 'grid', height: '100%', width: '100%', overflowY:'scroll' }} >
                    <div>
                        <span style={{ float: "left", paddingLeft: "20px" }}><h4><u>{isNew ? 'New ' : `Edit`} Task Instance</u></h4></span>
                        <div style={{ float: "left", paddingLeft: "15px", cursor: "pointer" }} onClick={this.handleHome}><FontAwesomeIcon icon={faHome} /></div>
                    </div> 
                    <div style={{ overflow: 'auto', paddingTop:'15px'}}>
                        <div className="dealForm"> 
                            <div className="row"> 
                                <div className="col"> 
                                    <b>Name:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Task'
                                        name='taskDetailId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable                                        
                                        onChange={this.handleDropDownChangeName}
                                        options={taskNameList}
                                        value={taskDetailId}
                                    />
                                </div>
                                <div className="col">
                                    <b>For Date:</b>
                                </div>
                                <div className="col">
                                    <DatePicker
                                        name="forDate"                                        
                                        selected={forDate ? new Date(forDate) : null}
                                        onChange={(date) => { 
                                            this.handleChangeNew('forDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                            }}
                                        autoComplete = "off"
                                        showYearDropdown
                                        showMonthDropdown
                                    /> 
                                </div> 
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input disabled="true" type="checkbox" name='hasSoftDeadline' checked={hasSoftDeadline} onChange={this.handleChangeCheckbox} />
                                    <b>&nbsp;{hasSoftDeadline ? "Soft Deadline":"No Soft Deadline"}</b>
                                </div>
                                <div className="col">
                                    { hasSoftDeadline ? 
                                        <DatePicker
                                            name="softDeadline"                                        
                                            selected={softDeadline ? new Date(softDeadline) : null}
                                            onChange={(date) => { 
                                                this.handleChangeNew('softDeadline', date ? moment(date).format("YYYY-MM-DD") : null)
                                                }}
                                            autoComplete = "off"
                                            showYearDropdown
                                            showMonthDropdown
                                        /> : <div></div>
                                    }
                                </div>
                                <div className="col"> 
                                    <b>Hard Deadline:</b>
                                </div>
                                <div className="col">
                                    <DatePicker
                                        name="hardDeadline"                                        
                                        selected={hardDeadline ? new Date(hardDeadline) : null}
                                        onChange={(date) => { 
                                            this.handleChangeNew('hardDeadline', date ? moment(date).format("YYYY-MM-DD") : null)
                                            }}
                                        autoComplete = "off"
                                        showYearDropdown
                                        showMonthDropdown
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>Status:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Status'
                                        name='statusId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable                                        
                                        onChange={this.handleDropDownChange}
                                        options={taskStatusList}
                                        value={statusId}
                                    />
                                </div>
                                <div className="col">
                                    <b>Completion Date:</b><br/> 
                                </div>
                                <div className="col">
                                    <DatePicker
                                        name="completionDate"                                        
                                        selected={completionDate ? new Date(completionDate) : null}
                                        onChange={(date) => { 
                                            this.handleChangeNew('completionDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                            }}
                                        autoComplete = "off"
                                        showYearDropdown                                        
                                        showMonthDropdown
                                    />
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>Remark:</b>  
                                    <JoditEditor
                                        value={remarkInstance}
                                        config={joditConfig}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={newRemark => this.setState({ newTaskInstance : {...this.state.newTaskInstance, remarkInstance: newRemark} })} // preferred to use only this option to update the content for performance reasons
                                        askBeforePasteHTML={false}
                                        askBeforePasteFromWord={false}
                                        defaultActionOnPaste="insert_clear_html"
                                    />
                                </div>
                            </div>

                            <button type="button" class="collapsible" onClick={ (e) => {this.setState({openMaster : !this.state.openMaster})} }>{ !this.state.openMaster ? "Show" : "Hide"}   Task Master Details</button>
                            <div style = { !this.state.openMaster ? {'display': 'none'} : {}}>
                                <div className="row" >
                                    <div className="col">
                                        <b>Frequency:</b>
                                    </div> 
                                    <div className="col">
                                        <Dropdown
                                            placeholder=''
                                            name='frequencyId'
                                            width="200px"
                                            fluid
                                            search
                                            selection
                                            clearable
                                            disabled
                                            onChange={this.handleDropDownChange}
                                            options={taskFrequencyList}
                                            value={frequencyId}
                                        />
                                    </div>
                                    <div className="col">
                                        <b></b>
                                    </div> 
                                    <div className="col">
                                        <b></b>
                                    </div> 
                                </div>
                            <div className="row">
                                <div className="col">
                                    <input type="checkbox" name='hasSoftDeadline' checked={hasSoftDeadline} onChange={this.handleChangeCheckbox} />
                                    <b>&nbsp;{hasSoftDeadline ? "Soft Deadline Days":"Soft Deadline?"}</b>
                                </div>
                                <div className="col">
                                    {hasSoftDeadline ?
                                        <NumberFormat
                                            placeholder={"Soft Deadline Days"}
                                            name='softDeadlineDays'
                                            value={Utils.getFormatted(softDeadlineDays)}
                                            thousandSeparator={true}
                                            onValueChange={(values) => { this.handleChangeNew('softDeadlineDays', values.floatValue ); }}
                                            //suffix={'M'}
                                            //prefix={'$'}
                                            disabled
                                            className={'form-control inputLightP'}
                                        /> : <div></div>
                                    }
                                </div>
                                <div className="col"> 
                                    <b>Hard Deadline Days:</b>
                                </div>
                                <div className="col">
                                    <NumberFormat 
                                        placeholder={"Hard Deadline Days"}
                                        name='hardDeadlineDays'
                                        value={Utils.getFormatted(hardDeadlineDays)}
                                        thousandSeparator={true}
                                        onValueChange={(values) => { this.handleChangeNew('hardDeadlineDays', values.floatValue ); }}
                                        //suffix={'M'}
                                        //prefix={'$'}
                                        disabled
                                        className={'form-control inputLightP'}
                                    />
                                </div>
                            </div>
                            <div className="row"> 
                                <div className="col">
                                    <b>End Date:</b><br/> 
                                </div>
                                <div className="col">
                                    <DatePicker
                                        name="endDate"                                        
                                        selected={endDate ? new Date(endDate) : null}
                                        onChange={(date) => { 
                                            this.handleChangeNew('endDate', date ? moment(date).format("YYYY-MM-DD") : null)
                                            }}
                                        autoComplete = "off"
                                        showYearDropdown
                                        disabled
                                        showMonthDropdown
                                    />
                                </div>
                                <div className="col">
                                    <b>Entity:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Entity'
                                        name='entityId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        disabled
                                        onChange={this.handleDropDownChange}
                                        options={taskEntityList}
                                        value={entityId}
                                    />
                                </div>
                            </div>
                            <div className="row"> 
                                <div className="col">
                                    <b>Jurisdiction / Regulator:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Jurisdiction / Regulator'
                                        name='jurisdictionId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        disabled
                                        onChange={this.handleDropDownChange}
                                        options={taskJurisdictionRegulatorList}
                                        value={jurisdictionId}
                                    />
                                </div>
                                <div className="col">
                                    <b>External Service Provider:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select External Service Provider'
                                        name='externalId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        disabled
                                        onChange={this.handleDropDownChange}
                                        options={taskExternalServiceProviderList}
                                        value={externalId}
                                    />
                                </div>
                            </div>
                            <div className="row"> 
                                <div className="col">
                                    <b>External Provider Contact Name:</b>
                                </div>
                                <div className="col">
                                    <input type="text"                                
                                        placeholder="External Provider Contact Name"
                                        name="externalContactName"
                                        autoComplete="off"
                                        value={externalContactName}
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChangeTextBox}
                                        disabled
                                    />
                                </div>
                                <div className="col">
                                    <b>Primary Person:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Primary Person'
                                        name='primaryUserId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        onChange={this.handleDropDownChange}
                                        options={userList}
                                        value={primaryUserId}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row"> 
                                <div className="col">
                                    <b>Secondary Person:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Primary Person'
                                        name='secondaryUserId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        disabled
                                        onChange={this.handleDropDownChange}
                                        options={userList}
                                        value={secondaryUserId}
                                        
                                    />
                                </div>
                                <div className="col">
                                    <b>Tertiary Person:</b>
                                </div>
                                <div className="col">
                                    <Dropdown
                                        placeholder='Select Tertiary Person'
                                        name='tertiaryUserId'
                                        width="200px"
                                        fluid
                                        search
                                        selection
                                        clearable
                                        disabled
                                        onChange={this.handleDropDownChange}
                                        options={userList}
                                        value={tertiaryUserId}
                                    />
                                </div>
                            </div>
                            <div className="row">  
                                <div className="col">
                                <b>Link:</b>  
                                <div className="col">
                                    <input type="text"      
                                        placeholder="Link"
                                        name="link"
                                        autoComplete="off"
                                        value={link}
                                        disabled
                                        className={'form-control inputLightP'}
                                        onChange={this.handleChangeTextBox}
                                    />
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>Remark:</b>  
                                    <JoditEditor
                                        value={remarkMaster}
                                        config={{ 
                                            readonly: true, // all options from https://xdsoft.net/jodit/doc/, 
                                            disabled: true,
                                            }}
                                        tabIndex={1} // tabIndex of textarea
                                        askBeforePasteHTML={false}
                                        askBeforePasteFromWord={false}
                                        defaultActionOnPaste="insert_clear_html"
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ float: "right", paddingDown: "15px", paddingRight: "30px", cursor: "pointer" }} onClick={this.save}>
                        <FontAwesomeIcon icon={faSave}  style = {{cursor: "pointer"}} tooltip = "true" title = "Save"/>
                    </div> 
                </div> 
                <Prompt when={this.state.isOpenGrid === false && JSON.stringify(existingTaskInstance) !== JSON.stringify(newTaskInstance)}
                    message="Do you want to discard changes?"/>
                <ModalForm modalType={modalType}
                    modalVisible={modalVisible}
                    modalHeading={modalHeading}
                    onClickAway={() => this.closeModal()}
                    onClickClose={() => this.closeModal()}
                    onClickYes={() => this.goHome()} />
            </div>
        );
    }
}

export default TaskInstanceNew;