/**<import>****************************************************************************************************/
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from 'semantic-ui-react';
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { fundBALService } from '../../bal/fund.bal';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-awesome-modal';

//https://fontawesome.com/icons?d=gallery
//https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from "@fortawesome/free-solid-svg-icons";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

// Our components
import config from '../../config';
import { Session } from '../../helper/session';

import TorGrid from '../torgrid/torgrid';
import ToastMessage from "../deals/components/toastMessage";

class Pnl extends Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.timeout = 250;
        this.gridApi = null;
        const user = JSON.parse(Session.getItem(config.token));
        const approverRole = user?.approvalKey?.filter(function (item) { return item && item.toLowerCase() === "markingtool" });

        this.state = {
            rowData: [],
            isChecked: false,
            isShowGrid: true,
            modalVisible: false,
            modalType: 'close',
            modalHeading: '',
            modalHandler: '',
            errorList: [],
            messageHeader: "Following Fields have not been filled in: ",
            showMessage: false,
            gridData: [],
            filterData: [],
            filterDataBtm: [],
            filterDataTop: [],
            filterDataMtdBtm: [],
            filterDataMtdTop: [],
            hideBottomTables: true,
            user: user,
            isApprover: (config.environment === 'local' && config.setAsApprover !== undefined ? config.setAsApprover : approverRole?.length > 0),
            num: -1,
            fundList: [],
            fund: 'ALL',
            forDate: moment(new Date()).format("YYYY-MM-DD"),
            maxDate: null
        };
        this.refresh = this.refresh.bind(this);

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.setAgGridAPI = this.setAgGridAPI.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalChange = this.handleModalChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.loadFunds = this.loadFunds.bind(this);
        this.cellValueChanged = this.cellValueChanged.bind(this);
        this.loadFunds();
        this.filterData = this.filterData.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        if (this.mounted) {
            this.connectServer();
        }
    }

    filterData = () => {
        let filterDataBackup = this.state.gridData;
        const {fund} = this.state;

        if(fund !== 'ALL') {
            filterDataBackup = filterDataBackup.filter(function (item) {
                return item['fund'] === fund
            });
        }

        const filterDataBtm = []; 
        const filterDataTop = [];
        const filterDataMtdBtm = []; 
        const filterDataMtdTop = []; 

        for (const item of filterDataBackup) {
            if (item["pnl total"] < 0) {
                filterDataBtm.push(item);
            } else if (item["pnl total"] > 0) {
                filterDataTop.push(item);
            }

            if (item["mtd pnl total"] < 0) {
                filterDataMtdBtm.push(item);
            } else if (item["mtd pnl total"] > 0) {
                filterDataMtdTop.push(item);
            }
        }

        // console.log(filterDataBtm, filterDataTop);

        this.setState({
            filterDataBtm: filterDataBtm,
            filterDataTop: filterDataTop,
            filterDataMtdBtm: filterDataMtdBtm,
            filterDataMtdTop: filterDataMtdTop
        })
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.gridData !== prevState.gridData || this.state.filterData !== prevState.filterData) {
            this.filterData();
        }
    }

    connectServer = () => {
        var ws = new WebSocket(config.wsUrl);
        var connectInterval;

        ws.onopen = () => {
            console.log("Connected...");
            //TODO: Need to send actual token
            ws.send('{"__messagetype__": "login", "token" : "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhc3JpdmFzdGF2YV90b3IiLCJ1c2VybmFtZSI6ImFzcml2YXN0YXZhX3RvciIsImlkIjo1LCJpYXQiOjE3MDc0MDI0NTAsImV4cCI6MTcwODAwNzI1MH0.Drrz3nilz54s9jGIUqjNRPmup6Cn0LOvc-FsXre_hjs"}');

            this.timeout = 250;
            clearTimeout(connectInterval);

            if (this.mounted) {
                console.log("<trader>Setting state...");
                this.setState({ ws: ws, display: "none" });
                //var _updateConnection = this.getData("updateConnection", JSON.parse(user));
                //ws.send(_updateConnection);
            }
        };

        ws.onmessage = e => {
            let data = JSON.parse(e.data);
            // console.log("Data ---->    ", data);

            switch (data.__MESSAGE__) {
                case "security_tick":
                    var res = data['price_current'] && !isNaN(data['price_current']);
                    if (res) {

                        var rows = this.state.gridData?.filter(row => row['position_id'] === data['position_id'])

                        if (rows && rows.length > 0) {
                            var rowsFound = rows.map(row1 => {
                                return {
                                    ...row1
                                    , ...data
                                    , 'cellcss': (row1['price_current'] < data['price_current'] ? 'cell-green' : row1['price_current'] > data['price_current'] ? 'cell-red' : 'cell-blue')
                                };
                            });

                            
                            rowsFound.forEach(row => {
                                if (row?.cellcss !==  'cell-blue') {
                                    console.log('CSS: ', row?.cellcss, row.position_id)
                                    const newGridData = [...this.state.gridData];

                                    const index = newGridData.findIndex((item) => item.position_id === row.position_id);

                                    if(index !== -1) {
                                        newGridData[index] = { ...row };
                                    }

                                    this.setState({
                                        gridData: newGridData
                                    })
                                }      
                            });

                            


                            if (JSON.stringify(rowsFound) !== JSON.stringify(rows)) {
                                this.setState({
                                    GridRowsToUpdate: rowsFound
                                });
                                // console.log("Updating Rows: ");
                                // console.log(rowsFound);
                            }
                            //this.gridApi.batchUpdateRowData({update: rows});
                        } else {
                            const rows = [{
                                ...data
                                , 'cellcss': 'cell-normal'
                            }];
                            const gridData = [...this.state.gridData, ...rows];
                            const filterData = [...this.state.filterData, ...rows];

                            this.setState({
                                gridData: gridData,
                                filterData: filterData
                            })

                            // console.log("Adding Rows: ");
                            // console.log(rows);
                        }
                    }
                    break;
                default:
                    break;
            }
        };

        ws.onerror = (e) => {
            console.log("On error message...");
            console.log(e);
            if (this.mounted) { this.setState({ display: 'block', error: `Socket is closed. Reconnecting in  ${Math.min(10000 / 1000, (this.timeout + this.timeout) / 1000)} second.` }); }
            //console.log();
            this.timeout = this.timeout + this.timeout;
            connectInterval = setTimeout(this.check, Math.min(10000, this.timeout));
            console.log("End On error message...");
        };
        ws.onclose = (e) => {
            if (ws != null) { ws.close(); }
            console.log("completed on close message...");
        };
    };

    loadFunds() {
        fundBALService.getRowData().then(data => {
            if (data) {
                this.setState({
                    fundList: data.map(item => { return { key: item.display_name, value: item.display_name, text: item.code } })
                });
            }
        }, error => {
            this.setState({
                showMessage: true,
                errorList: [error],
                messageHeader: "Error while getting the fund list:",
                modalVisible: true,
                modalType: 'close',
                modalHandler: 'close',
                modalHeading: 'Fund list could not be retrieved, please contact support!'
            });
            console.log(error);
        });
    }

    handleChange(name, value) {
        this.setState({ [name]: value });
    }

    toggleView(e) {
        this.setState({ hideBottomTables: !this.state.hideBottomTables });
    }

    refresh(e, modalVisible = false) {
        this.connectServer();
    }

    closeModal() {
        if (this.state.modalHandler === 'refresh') {
            window.location.reload();
        }
        this.setState({
            modalVisible: false
        });
    }

    handleModalChange() {
        if (this.state.modalHandler === 'refresh') {
            this.setState({ modalVisible: false });
        } else if (this.state.modalHandler === 'save') {
            this.setState({ modalVisible: false });
        } else {
            this.setState({ modalVisible: false });
        }
    }

    closeMessage() {
        this.setState({ showMessage: false, errorList: [] });
    }

    setAgGridAPI(api) {
        this.gridApi = api;
        this.gridApprovalApi = api;
    }

    handleDropDownChange(e, { name, value }) {
        const { gridData } = this.state;
        this.setState({ [name]: value });

        if (value === "ALL") {
            this.setState({ filterData: gridData })
        } else {
            var filterData = gridData.filter(function (item) {
                return item['fund'] === value
            });

            this.setState({
                filterData: filterData
            })
        }
    }

    render() {
        if (this.state.redirectTo)
            return <Redirect to={this.state.redirectTo} />;
        return this.getPage();
    }

    cellValueChanged(e) {
        if (e && e.data) {
            let col1 = Object.keys(e.data).filter(p => p.startsWith("col001_"));
            let data = this.state.gridData.filter(p => p['Investment Code'] === e.data['Investment Code'] && p.instrument_id !== e.data.instrument_id);

            data = data.map(row => {
                return {
                    ...row,
                    [col1]: e.data[col1],
                    isBloomberg: e.data.isBloomberg
                }
            })

            this.setState({
                GridRowsToUpdate: data
            });
        }
    }

    getPage() {
        const { GridRowsToUpdate, modalHeading, hideBottomTables, filterData, filterDataBtm, filterDataTop, isApprover, fundList, fund, showMessage, messageHeader, errorList } = this.state;
        if(this.state.gridData.length > 0) {
            return (
                <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }} >
                    {
                        showMessage ?
                            <ToastMessage
                                header={messageHeader}
                                errorList={errorList}
                                closeMessage={this.closeMessage}
                            /> : <div></div>
                    }
                    <div style={{ gridTemplateRows: 'auto 1fr', display: 'grid', height: '100%', width: '100%' }}>
                        <div>
                            <div style={{ float: "Left", padding: "5px 15px 0px 15px" }} ><h4><u>Public Pnl Dashboard</u></h4></div>
                            <div style={{ float: "right", padding: "15px 15px", cursor: "pointer" }} onClick={this.refresh}><FontAwesomeIcon icon={faSync} /></div>
                            <div style={{ float: "right", height: "50px", padding: "10px 0px 0px 10px", cursor: "pointer", minWidth: "200px" }}>
                                <Dropdown
                                    placeholder='Select Fund'
                                    name="fund"
                                    fluid
                                    search
                                    selection
                                    onChange={this.handleDropDownChange}
                                    options={fundList}
                                    value={fund}
                                    className={'form-control'}
                                />
                            </div>
                            <div style={{ float: "right", height: "50px", padding: "10px 0px 0px 10px" }}>
                                <button key='approveButton' className="blueB-no-margin" onClick={this.toggleView}>{hideBottomTables ? "Show " : "Hide"} Top Movers</button>
                            </div>
                        </div>
                        <div style={hideBottomTables === true ? { gridTemplateRows: '1fr', display: 'grid', width: '100%' } : { gridTemplateRows: '5fr 2fr', display: 'grid', width: '100%' }}>
                            <div style={{ height: '100%', width: '100%' }}>
                                <TorGrid
                                    setAgGridAPI={this.setAgGridAPI}
                                    isHideDateInputs={true}
                                    domLayout="normal"
    
                                    isHideSaveGridLayout={true}
    
                                    rowData={filterData}
                                    GridRowsToUpdate={GridRowsToUpdate}
    
                                    className="ag-theme-alpine"
                                    rowSelection="multiple"
                                    gridIdentityColumn="position_id"
    
                                    groupDefaultExpanded={1}
                                    groupIncludeTotalFooter={false}
    
                                    guikey="publicPnL"
                                    sortColumn={true}
    
                                    singleClickEdit={true}
                                    isApprover={isApprover}
                                    groupColumns={['root', 'fund']}
                                    cellValueChanged={this.cellValueChanged}
                                />
                            </div>
                            {
                                hideBottomTables
                                    ?
                                    <div></div>
                                    :
                                    <div style={{ width: '100%', marginTop: '10px', marginLeft: '30px', marginRight: '30px' }}>
                                        <div style={{ gridTemplateColumns: '1fr 1fr', display: 'grid', width: '100%' }}>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <TorGrid
                                                    guikey="bottomDODpnl"
                                                    isHideDateInputs={true}
                                                    isHideSaveGridLayout={true}
    
                                                    rowData={filterDataBtm}
                                                    ignoreBlanks='pnl total'
                                                    domLayout="autoHeight"
    
                                                    suppressExpandablePivotGroups={true}
                                                    className="dashboardTopGrid"
                                                    rowGroupPanelShow="none"
                                                    enableFilter={false}
    
                                                    sideBar='none'
                                                    gridTitle='Bottom DoD'
                                                    pagination={true}
                                                    paginationPageSize={5}
                                                    gridIdentityColumn="position_id"
                                                />
                                            </div>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <TorGrid
                                                    guikey="topDODpnl"
                                                    isHideDateInputs={true}
                                                    isHideSaveGridLayout={true}
    
                                                    rowData={filterDataTop}
                                                    ignoreBlanks='pnl total'
                                                    domLayout="autoHeight"
    
    
                                                    suppressExpandablePivotGroups={true}
                                                    className="dashboardTopGrid"
                                                    rowGroupPanelShow="none"
                                                    enableFilter={false}
    
                                                    sideBar='none'
                                                    gridTitle='Top DoD'
                                                    pagination={true}
                                                    paginationPageSize={5}
                                                    gridIdentityColumn="position_id"
                                                />
                                            </div>
                                            {/* <div style={{ height: '100%', width: '100%' }}>
                                                <TorGrid
                                                    guikey="bottomMTDpnl"
                                                    isHideDateInputs={true}
                                                    isHideSaveGridLayout={true}
    
                                                    rowData={filterDataMtdBtm}
                                                    ignoreBlanks='mtd pnl total'
                                                    domLayout="autoHeight"
    
    
                                                    suppressExpandablePivotGroups={true}
                                                    className="dashboardTopGrid"
                                                    rowGroupPanelShow="none"
                                                    enableFilter={false}
    
                                                    sideBar='none'
                                                    gridTitle='Bottom MTD'
                                                    pagination={true}
                                                    paginationPageSize={5}
                                                    gridIdentityColumn="position_id"
                                                />
                                            </div>
                                            <div style={{ height: '100%', width: '100%' }}>
                                                <TorGrid
                                                    guikey="topMTDpnl"
                                                    isHideDateInputs={true}
                                                    isHideSaveGridLayout={true}
    
                                                    rowData={filterDataMtdTop}
                                                    ignoreBlanks='mtd pnl total'
                                                    domLayout="autoHeight"
    
    
                                                    suppressExpandablePivotGroups={true}
                                                    className="dashboardTopGrid"
                                                    rowGroupPanelShow="none"
                                                    enableFilter={false}
    
                                                    sideBar='none'
                                                    gridTitle='Top MTD'
                                                    pagination={true}
                                                    paginationPageSize={5}
                                                    gridIdentityColumn="position_id"
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div>
                        <section>
                            {
                                this.state.modalType === 'close'
                                    ?
                                    <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={() => this.closeModal()}>
                                        <div className="p20 centerElem" style={{ width: "420px", height: "220px" }}>
                                            <h1>{modalHeading}</h1>
                                            <button className="graybtnDes mt100" onClick={this.closeModal}>Close</button>
                                        </div>
                                    </Modal>
                                    :
                                    this.state.modalType === 'saveChanges'
                                        ?
                                        <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                            <div className="p20 centerElem" style={{ width: "420px", height: "220px" }}>
                                                <h1>{modalHeading}</h1>
                                                <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                                <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                            </div>
                                        </Modal>
                                        :
                                        <Modal visible={this.state.modalVisible} effect="fadeInUp" onClickAway={this.closeModal}>
                                            <div className="p20 centerElem" style={{ width: "420px", height: "220px" }}>
                                                <h1>{modalHeading}</h1>
                                                <button className="btnDes mt100" onClick={this.handleModalChange}>Yes</button>
                                                <button className="graybtnDes mt100 ml20" onClick={this.closeModal}>No</button>
                                                <button className="graybtnDes mt100 ml20" onClick={() => { this.setState({ modalVisible: false }); }}>Cancel</button>
                                            </div>
                                        </Modal>
                            }
                        </section>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
                    <img src={require('../../images/Loading.gif')} alt="Loading..." />
                </div>
            );
        }
    }
}

export default Pnl;