/**<import>***************************************************************************************************
 * http://www.ajaxload.info/ - For generating Loading gif image
 * https://blog.ag-grid.com/persisting-ag-grid-state-with-react-redux/ - Ag grid persisting columns layout.
 * https://react.semantic-ui.com/modules/dropdown/ - React dropdown
 * https://fontawesome.com/icons?d=gallery - Fonts
 * https://stackoverflow.com/questions/56559772/where-do-i-find-the-object-names-of-icons-in-the-fontawesome-free-packages - Fonts
*/

import CheckboxRenderer from "../common/CheckboxRenderer";

import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import { Utils } from './../../helper/utils'; 
// Ag-Grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { LicenseManager } from "@ag-grid-enterprise/core";
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import NumericEditor from "./numericEditor";
import TextAreaEditor from "./textAreaEditor"; 
import {RichTextEditor} from "./richTextEditor"; 
import FileAttachmentEditor from "./fileAttachmentEditor";

import RemoveButton from "./removeButton";
// Our components
import { userBALService } from '../../bal/user.bal';
import { torGridBALService } from '../../bal/torGridBALService.bal';
import { gridLayoutBALService } from '../../bal/gridLayout.bal';
import './torgrid.css'; 

import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'; 
import { ModuleRegistry } from '@ag-grid-community/core';
 import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    CsvExportModule,
    ExcelExportModule,
    MasterDetailModule
]);
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-045460 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Tor Investment Management )_is_granted_a_( Single Application )_Developer_License_for_the_application_( DDS )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( DDS )_need_to_be_licensed___( DDS )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 21 September 2024 )____[v2]_MTcyNjg3MzIwMDAwMA==cb1b566e378c4f55166d73f9a205d0e8");
 
const $ = window.$;
const {config} = require('../../config');

class TorGrid extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.timeout = 250;
    //this.getDatePicker = this.getDatePicker.bind(this);
    this.state = {
      GridId: props.guikey ?? this.constructor.name + "_default"
      , GridIdentityColumn: props.gridIdentityColumn
      , GridTitle: props.gridTitle
      , IsHideDateInputs: props.isHideDateInputs === true
      , isHideSaveGridLayout: props.isHideSaveGridLayout === true
      , GridLinkColumn: props.gridLinkColumn
      , GridEditColumn: props.gridEditColumn
      , GridApproveColumn: props.gridApproveColumn
      , GridColumnList: []
      , GridGroupColumnDef: {}
      , GridLayoutIsGlobal: false
      , GridLayoutRemoveDisplay: "none"
      , GridFetchAgain: true
      , GridLayoutChanged: false
      , GridTypeList: []
      , GridTypeSelected: null
      , GridTypeName: ""
      , GridShowType: true
      , GridLoadInProgress: false
      , GridRowsToUpdate: []
      , GridRawcolumnList: []
      , GridModules: [AllModules, AllCommunityModules]
      , GridStyle: this.props.GridStyle ? this.props.GridStyle: this.props.GridWidth ? { height: '100%', margin: 'auto', overflow: 'hidden', width: this.props.GridWidth } : { height: '100%', margin: 'auto', overflow: 'hidden', width: '100%' }
      , DialogOpen: false
      , sideBar: {
        toolPanels: ['columns'],
        defaultToolPanel: ''
      }
      , modules: [ClientSideRowModelModule, RowGroupingModule, SetFilterModule, MenuModule, ColumnsToolPanelModule]      
      , frameworkComponents : { fileAttachmentEditor : FileAttachmentEditor, richTextEditor: RichTextEditor, textAreaEditor: TextAreaEditor, numericEditor: NumericEditor, checkboxRenderer: CheckboxRenderer, removeButton : RemoveButton  }
      , components : { datePicker: getDatePicker(this.props.dateFormat) }
      , ws: null
      , showPopup: false
      , display: "none"
      , isOpenGrid: false
      , isOpenLoad: true
      , isLoading: false
      , rowData: []
      , detailCellRendererParams : this.props.detailCellRendererParams ?? torGridBALService.getDetailCellRendererParams(props.guikey ?? this.constructor.name + "_default")
      , rowGroupPanelShow: 'always'
      , AsOfDate: null
      , Frequency: 'm'
      , Fund: null
      , MessageBoxBackground: "Red"
      , FrequencyList: [{ key: "m", text: "Monthly", value: "m" }
        , { key: "d", text: "Daily", value: "d" }
        , { key: "w", text: "Weekly", value: "w" }
        , { key: "q", text: "Quaterly", value: "q" }
      ]
    }

    this.refresh = this.refresh.bind(this);
    this.saveGridLayout = this.saveGridLayout.bind(this);
    this.removeGridLayout = this.removeGridLayout.bind(this);
    this.handleCloseErrorBox = this.handleCloseErrorBox.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleChangeGridText = this.handleChangeGridText.bind(this);
    this.setGridLayout = this.setGridLayout.bind(this);
    this.getGridLayout = this.getGridLayout.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.openErrorBox = this.openErrorBox.bind(this);
    this.getSelectedGridType = this.getSelectedGridType.bind(this);
    this.handleCheckGridLayoutIsGlobal = this.handleCheckGridLayoutIsGlobal.bind(this);
    this.handleDropDownChangeGridType = this.handleDropDownChangeGridType.bind(this);
    this.intervalFunc = this.intervalFunc.bind(this);
    this.showError = this.showError.bind(this);
    this.getLink = this.getLink.bind(this);
    Utils.wAvgRating = Utils.wAvgRating.bind(this);
    this.exportDataAsExcel = this.exportDataAsExcel.bind(this);
    this.shouldRowBeSkipped = this.shouldRowBeSkipped.bind(this);
    this.rowGroupCallback = this.rowGroupCallback.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.processColumn = this.processColumn.bind(this);
    this.getDefaultIsOpenGrid = this.getDefaultIsOpenGrid.bind(this);
    this.addNew = this.addNew.bind(this);
    this.refresh();
  }

  getDefaultIsOpenGrid(defaultValue) {
    return (this?.props?.isOpenGrid !== undefined) ? this?.props?.isOpenGrid : defaultValue;
  }

  componentDidMount() {
    //this.worker = new WebWorker(torgridworker);
    //this.startWorker();
    this.mounted = true;
    //if (this.mounted) this.connectServer();
  }

  componentDidUpdate(prevProps) {
    //console.log(prevProps)

    if (prevProps.rowData !== this.props.rowData || prevProps.query !== this.props.query || prevProps.toggleRefresh !== this.props.toggleRefresh) {
      this.refresh();
    }
    else if(prevProps.GridRowsToAdd !== this.props.GridRowsToAdd) {
      this.gridApi.batchUpdateRowData({ add: this.props.GridRowsToAdd });
    }
    else if(prevProps.GridRowsToUpdate !== this.props.GridRowsToUpdate) {
      this.gridApi.batchUpdateRowData({ update: this.props.GridRowsToUpdate });
    } else if ((prevProps.groupBy ?? '') !== (this.props.groupBy ?? '') || JSON.stringify(prevProps.groupColumns ?? []) !== JSON.stringify(this.props.groupColumns ?? [])) {
      this.setState({
        isLoading: true,
        isOpenGrid: false
      }, async () => {
        var columnList = this.state.GridRawcolumnList.map(column => {
          return {
            ...column,
            rowGroup: column.rowGroup || (column.col && column.col !== '' && column.col === this.props.groupBy) || (column.col && column.col !== '' && this.props.groupColumns && this.props.groupColumns.includes(column.col))
            , rowGroupIndex: (column.col && this.props.groupColumns && this.props.groupColumns.includes(column.col)) ? this.props.groupColumns.indexOf(column.col) : -1
          }
        });
        
        var groupColumnList = Utils.getColumnList(columnList);

        if(this.props.sortColumn !== true || (this.props.sortColumn && this.state.rowData?.length > 0)) {
          this.setState({ 
            GridColumnList: groupColumnList
          }, async () => {
            await new Promise(resolve => setTimeout(resolve, 2000));
          
            await this.refresh();

            this.setState({
              isLoading: false,
              isOpenGrid: true
            });
          });
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.mounted) {
      const { ws } = this.state;
      //console.log('Trader : componentWillUnmount');
      if (ws !== null && ws.readyState !== WebSocket.CLOSED) {
        ws.close();
        this.setState({ ws: ws });
      }
    }
  }

  connectServer = () => {
    var ws = new WebSocket(config.wsUrl);
    var connectInterval;

    ws.onopen = () => {
      //console.log("Connected...");
      const { user } = this.state;
      this.timeout = 250;
      clearTimeout(connectInterval);

      if (this.mounted) {
        //console.log("<trader>Setting state...");
        this.setState({ ws: ws, display: "none" });
        var _updateConnection = this.getData("updateConnection", JSON.parse(user));
        ws.send(_updateConnection);
      }
    };

    ws.onmessage = e => {
      //console.log("On new message...");
      //const { user } = this.state;
      let data = JSON.parse(e.data);
      //console.log("New Message - " + row.Traders);

      switch (data.__MESSAGE__) {
        case "security_tick":
          var res = data['PX_LAST'] && !isNaN(data['PX_LAST']);
          if (res) {
            var security = data['SECURITIES'];
            var rows = this.state.rowData?.filter(row => row['cusip'] === security || row['investment code'] === security || row['isin'] === security)

            if (rows && rows.length > 0) {
              var rowsFound = rows.map(row1 => {
                const newprice = parseFloat(data['PX_LAST']) + Utils.randomBetween(1, 10) / 100;
                return {
                  ...row1
                  , 'ending local price': newprice
                  , 'Mark_wAvg1': newprice
                  , 'color': row1['ending local price'] < data['PX_LAST'] ? "green" : "red"
                };
              });

              rowsFound.forEach(row => {
                this.state.GridRowsToUpdate.push(row);
              });
              //this.gridApi.batchUpdateRowData({update: rows});
            }
          }
          break;
        default:
          break;
      }
      //console.log(tradersList);
    };

    ws.onerror = (e) => {
      //console.log("On error message...");
      if (this.mounted) { this.setState({ display: 'block', error: `Socket is closed. Reconnecting in  ${Math.min(10000 / 1000, (this.timeout + this.timeout) / 1000)} second.` }); }
      //console.log();
      this.timeout = this.timeout + this.timeout;
      connectInterval = setTimeout(this.check, Math.min(10000, this.timeout));
      //console.log("End On error message...");
    };
    ws.onclose = (e) => {
      if (ws != null) { ws.close(); }
      //console.log("completed on close message...");
    };
  };
  /**</connectServer>*******************************************************************************************/

  /**<check>***************************************************************************************************/
  check = () => {
    const { ws } = this.state;
    //check if websocket instance is closed, if so call `connectServer` function.
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connectServer();
  };
  /**</check>*************************************************************************************************/

  /**<getData>**************************************************************************************************/
  getData(messageType, payload) {
    //Copy the values from the payload object, if one was supplied
    var payloadCopy = {};
    if (payload !== undefined && payload !== null) {
      var keys = Object.keys(payload);
      for (var index in keys) {
        var key = keys[index];
        payloadCopy[key] = payload[key];
      }
    }
    payloadCopy['__MESSAGE__'] = messageType;
    return JSON.stringify(payloadCopy);
  }
  /**</getData>*************************************************************************************************/

  handleChangeGridText(e) {
    const { value } = e.target;
    this.setState({ GridTypeName: value });
  }

  handleClickOpen = () => {
    this.setState({ DialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ DialogOpen: false });
  };

  openErrorBox(error) {
    const error_message = `Error loading data - ${error}`
    this.setState({
      display: "inline",
      MessageBoxBackground: "Red",
      error: error_message
    });
  }

  openMessageBox(message) {
    this.setState({
      display: "inline",
      MessageBoxBackground: "Green",
      error: message
    });
  }

  getSelectedGridType() {
    var list = this.state.GridTypeList.filter(gridLayout => gridLayout.gridLayoutId === this.state.GridTypeSelected)
    var result = null;
    if (list && list.length > 0)
      result = list[0].type;
    return result;
  }

  removeGridLayout() {
    const { GridTypeSelected, GridId } = this.state;
    if (GridTypeSelected === 0) this.openErrorBox('Cannot remove!');
    const gridLayoutType = this.getSelectedGridType();
    if (!gridLayoutType || gridLayoutType === "") {
      this.openErrorBox('Grid Name cannot be blank');
      return;
    }
    gridLayoutBALService.removeGridLayout(GridTypeSelected, GridId).then(data => {
      //var d = JSON.parse(data);
      this.getGridLayout(gridLayoutType);
      this.setState({ GridTypeName: "" });
      this.openMessageBox("Grid Layout have been removed successfully!!!");
    }
      , error => {
        this.openErrorBox(error);
        console.log(error);
      });
  }

  saveGridLayout() {
    this.handleCloseErrorBox();
    const gridLayoutType = this.state.GridTypeSelected === 0 ? this.state.GridTypeName : this.getSelectedGridType();
    if (!gridLayoutType || gridLayoutType === "") {
      this.openErrorBox('Grid Name cannot be blank');
      return;
    }

    const { GridTypeSelected, GridId, GridLayoutIsGlobal } = this.state;
    //const {GridSortModel, GridFilterModel, GridColumnState, GridColumnGroupState, GridIsPivotMode } = this;

    var GridSortModel = this.gridApi?.getSortModel();
    var GridFilterModel = this.gridApi?.getFilterModel();
    var GridColumnState = this.gridColumnApi?.getColumnState();
    var GridColumnGroupState = this.gridColumnApi?.getColumnGroupState();
    var GridIsPivotMode = this.gridColumnApi?.isPivotMode();

    gridLayoutBALService.saveGridLayout(
      GridTypeSelected
      , GridId
      , gridLayoutType
      , GridSortModel
      , GridFilterModel
      , GridColumnState
      , GridColumnGroupState
      , GridIsPivotMode
      , GridLayoutIsGlobal
    ).then(data => {
      //var d = JSON.parse(data);
      this.getGridLayout(gridLayoutType);
      this.setState({ GridTypeName: "" });
      this.openMessageBox("Grid Layout have been saved successfully!!!");
    }
      , error => {
        this.openErrorBox(error);
        console.log(error);
      });
  }

  getLink(params, action) {
    var link = document.createElement('a');
    link.href = '_blank';
    link.target = '_blank'
    link.innerText = action === "Show Changes" || action === "Edit" ? (this.props.isRowSelectable(params.node) ? action : '') : (params.value ?? '');
    //link.style = "cursor: 'pointer";
    link.addEventListener('click', (e) => {
      e.preventDefault();
      var a = { target: { name: this.state.GridIdentityColumn, value: params.data[this.state.GridIdentityColumn], data: params.data, action: action } };
      this.props.handleRowSelected(a);
    });
    return link;
  }

  refresh() {
    this.setState({
      isOpenGrid: false,
      isOpenLoad: true,
      display: "none",
      ...(this.props.sortColumn) &&  {GridColumnList: [], GridRawcolumnList: []}
    });

    var columnPromise = [];

    if(this.props.toggleColumn) {
      columnPromise = torGridBALService.getColumnListToggle(this.state.GridId);
    } else {
      columnPromise = torGridBALService.getColumnList(this.state.GridId);
    }
    
    if (columnPromise)
      columnPromise.then(data => {
        var gridColumns = data;
        var columnList = gridColumns.columnSpecs.filter(gridColumn => gridColumn['ag-grid'] === true);

        columnList = columnList.map(gridColumn => {
            return this.processColumn(gridColumn)
        });

      var SnPRatingMap = gridColumns.SnPRatingMap;
      var SnPRatingMapReverse = {};
      if (gridColumns.SnPRatingMap) {
        Object.keys(SnPRatingMap).forEach(element => {
          SnPRatingMapReverse[SnPRatingMap[element]] = element;
        });
      }

      if (this.props.rowData) {
        columnList = this.processRows(this.props.rowData, columnList, SnPRatingMapReverse, SnPRatingMap);
        //this.handleDropDownChangeGridType(null, {value : this.state.GridTypeSelected, isRefresh : true });
      } else if (this.props.query) {
        torGridBALService.getRowData(this.state.GridId, { query: this.props.query, RowData: this.props.RowData, Fund: this.state.Fund ?? this.props.Fund, AsOfDate: this.state.AsOfDate ?? this.props.AsOfDate, Frequency: this.props.Frequency ??this.state.Frequency  }).then(data => {
          columnList = this.processRows(data, columnList, SnPRatingMapReverse, SnPRatingMap, true);
        }, error => {
          this.showError(error);
        });
      } else {
        columnList = this.processRows([], columnList, SnPRatingMapReverse, SnPRatingMap);
      }
    }, error => {
      this.showError(error);
    });
  }

  processColumn(gridColumn) {
    var fieldName = Utils.getFieldName(gridColumn.display, gridColumn.agg ?? gridColumn.aggfunc);
    var isCol = gridColumn.agg !== "wAvg" && gridColumn.agg !== "geometricProduct" && gridColumn.agg !== "notionalSum" && gridColumn.agg !== "portfolioReturn" && gridColumn.aggfunc !== "wAvgRating" && gridColumn.agg !== "sumif" && gridColumn.agg !== "pctif" && gridColumn.agg !== "percent" && gridColumn.agg !== "aggDiv" && gridColumn.agg !== "aggDivIf" && gridColumn.agg !== "dominant" && gridColumn.agg !== "count";
    return {
      ...gridColumn
      , rowGroup: gridColumn.rowGroup || (gridColumn.col && this.props.groupBy === gridColumn.col) || (gridColumn.col && this.props.groupColumns && this.props.groupColumns.includes(gridColumn.col))
      , rowGroupIndex:(gridColumn.col && this.props.groupColumns && this.props.groupColumns.includes(gridColumn.col)) ? this.props.groupColumns.indexOf(gridColumn.col) : -1
      , headerName: gridColumn.display
      , headerTooltip: gridColumn.display
      , ...(isCol) && { field: gridColumn.col }
      , ...(!isCol && gridColumn.agg !== "wAvg" && gridColumn.agg !== "geometricProduct" && gridColumn.agg !== "notionalSum" && gridColumn.agg !== "portfolioReturn" && gridColumn.aggfunc !== "wAvgRating" && gridColumn.agg !== "aggDivIf") && { field: fieldName }
      , ...(gridColumn.agg === "wAvg") && { field: fieldName + "1" }
      , ...(gridColumn.aggfunc === "wAvgRating") && { field: fieldName + "2" }
      , ...(gridColumn.agg === "aggDivIf") && { field: fieldName + "3" }
      , ...(gridColumn.agg === "count") && { field: fieldName + "4" }
      , ...(gridColumn.agg === "geometricProduct") && { field: fieldName + "5" }
      , ...(gridColumn.agg === "portfolioReturn") && { field: fieldName + "6" }
      , ...(gridColumn.agg === "notionalSum") && { field: fieldName + "7" }
      , valueFormatter: Utils.agValueFormatter
      , cellStyle: Utils.cellClassNegative
      , headerClass: 'resizable-header'
      , resizable: true
      , ...((gridColumn.agg === "sum" || gridColumn.agg === "min") && { aggFunc: gridColumn.agg })
      , ...((gridColumn.agg === "uniq") && { aggFunc: Utils.uniq })
      , ...((gridColumn.agg === "wAvg") && { aggFunc: Utils.wAvg })
      , ...((gridColumn.agg === "geometricProduct") && { aggFunc: Utils.geometricProduct })
      , ...((gridColumn.agg === "notionalSum") && { aggFunc: Utils.notionalSum })
      , ...((gridColumn.agg === "portfolioReturn") && { aggFunc: Utils.portfolioReturn })
      , ...((gridColumn.aggfunc === "wAvgRating") && { aggFunc: Utils.wAvgRating })
      , ...((gridColumn.agg === "aggDiv") && { aggFunc: Utils.aggDiv })
      , ...((gridColumn.agg === "dominant") && { aggFunc: Utils.dominant })
      , ...((gridColumn.agg === "count") && { aggFunc: Utils.countUnique })
      , ...((gridColumn.agg === "aggDivIf") && { aggFunc: Utils.aggDivIf })
      , ...((gridColumn.agg === "sumif" || gridColumn.agg === "pctif" || gridColumn.agg === "percent") && { aggFunc: "sum" })
      , ...(gridColumn.display === this.state.GridLinkColumn) && {
        cellRenderer: (params) => { return this.getLink(params, "Link") }
      }
      , ...(gridColumn.display === this.state.GridEditColumn) && {
        cellRenderer: (params) => { return this.getLink(params, "Edit") }
      }
      , ...(gridColumn.display === this.state.GridApproveColumn) && {
        cellRenderer: (params) => { return this.getLink(params, "Show Changes") }
      }
    }
  }

  processRows(data, columnList, SnPRatingMapReverse, SnPRatingMap) {
    if(data && data.length > 0) {
      columnList = columnList.map(gridColumn => {
        let display = gridColumn.display;
        let header = '';
        if(display.includes('.')) {
          const values = display.split('.');
          display = values[1];
          header = values[0] + '.';
        }
        if(display?.startsWith('!{col') && display?.endsWith('}')) {
          let column = display.substring(2, display.length - 1 );
          let columns = Object.keys(data[0]).filter(col =>col.startsWith(column));
          if(columns?.length > 0)
            return this.processColumn({
              ...gridColumn,
              display: header + columns[0].substring(7),
              col: columns[0].replace('.', ''),
              col3: columns[0]
            });
        }
        return gridColumn;
      });
    }
    
    var rowData = data;

    columnList.forEach(gridColumn => {
      var filterList = null;
      var numcol = gridColumn.agg === "aggDivIf" ? gridColumn.col : gridColumn.numcol;
      var denomcol = gridColumn.agg === "aggDivIf" ? gridColumn.col : gridColumn.denomcol;
      var denomvalue = 0;
      var fieldName = Utils.getFieldName(gridColumn.display, gridColumn.agg ?? gridColumn.aggfunc);

      if (gridColumn.fltr) {
        var filters = Object.keys(gridColumn.fltr)
        filterList = filters.map(filter => {
          return {
            column: filter
            , value: gridColumn.fltr[filter]
          }
        });
      }

      var rowDataFiltered = rowData;
      if (gridColumn.agg === "pctif" && filterList && filterList.length > 0) {
        rowDataFiltered = rowData;
        filterList.forEach(filter => {
          rowDataFiltered = rowDataFiltered.filter(row => row[filter.column] === filter.value && denomcol && numcol && row[numcol] && row[denomcol]);
        });
        denomvalue = rowDataFiltered.reduce((denominator, row) => denominator + row[denomcol], 0);
      } else if (gridColumn.agg === "percent" && gridColumn.cols && gridColumn.cols.length > 1) {
        denomvalue = rowData.reduce((denominator, row) => denominator + row[gridColumn.cols[1]], 0);
      }
      //var row_id = 0;
      //var GridIdentityColumn = this.state.GridIdentityColumn;
      const { ignoreBlanks } = this.props;

      if (ignoreBlanks) {
        rowData = rowData.filter(row => (row && row[ignoreBlanks] && row[ignoreBlanks] !== 0 && row[ignoreBlanks] !== ''));
      }

      rowData = rowData.map(row => { //++row_id;                  
        var newRow = {
          ...row
          , ...(gridColumn?.col2?.startsWith('!{col') && gridColumn?.col2?.endsWith('}')) && {[gridColumn.col]: row[gridColumn.col3]}
          , ...(gridColumn.aggfunc === "wAvgRating" && !(row['rating num'] && row['asset mv'] && row['rating num'] !== 0)) && { [fieldName + '2']: Utils.createValueObject2(0, 0, 'NR') }
          , ...(gridColumn.aggfunc === "wAvgRating" && row['rating num'] && row['asset mv'] && row['rating num'] !== 0) && { [fieldName + '2']: Utils.createValueObject2(row['rating num'], row['asset mv'], SnPRatingMapReverse[row['rating num']] ? SnPRatingMapReverse[row['rating num']] : 'NR') }
          , ...(gridColumn.agg === "wAvg" && !(gridColumn.cols && gridColumn.cols[0] && row[gridColumn.cols[0]])) && { [fieldName + '1']: Utils.createValueObject2(0, 0, 0) }
          , ...(gridColumn.agg === "wAvg" && gridColumn.cols && gridColumn.cols[0] && row[gridColumn.cols[0]]) && { [fieldName + '1']: Utils.createValueObject2(row[gridColumn.cols[0]], row[gridColumn.cols[1]], Utils.getValueFormatted(row[gridColumn.cols[0]])) }
          , ...(gridColumn.agg === "geometricProduct" && !(gridColumn.col)) && { [fieldName + '5']: 0 }
          , ...(gridColumn.agg === "geometricProduct" && gridColumn.col) && { [fieldName + '5']: row[gridColumn.col] }
          , ...(gridColumn.agg === "notionalSum" && !gridColumn.col) && { [fieldName + '7']: 0 }
          , ...(gridColumn.agg === "notionalSum" && gridColumn.col ) && { [fieldName + '7']: row[gridColumn.col] }
          , ...(gridColumn.agg === "portfolioReturn" && !(gridColumn.args)) && { [fieldName + '6']: 0 }
          , ...(gridColumn.agg === "portfolioReturn" && gridColumn.args) && { [fieldName + '6']: Utils.getDivide(row[gridColumn.args[0]], row[gridColumn.args[1]]) }
          , ...(gridColumn.agg === "sumif" && Utils.doesMatchFilter(row, filterList) && row[gridColumn.col]) && { [fieldName]: row[gridColumn.col] }
          , ...(gridColumn.agg === "sumif" && !(Utils.doesMatchFilter(row, filterList) && row[gridColumn.col])) && { [fieldName]: 0 }
          , ...(gridColumn.agg === "pctif" && !(Utils.doesMatchFilter(row, filterList) && denomcol && numcol && row[numcol] && row[denomcol] && row[denomcol] !== 0)) && { [fieldName]: 0 }
          , ...(gridColumn.agg === "pctif" && Utils.doesMatchFilter(row, filterList) && denomcol && numcol && row[numcol] && row[denomcol] && row[denomcol] !== 0) && { [fieldName]: row[numcol] / denomvalue }
          , ...(gridColumn.agg === "percent" && gridColumn.cols && gridColumn.cols.length > 1) && { [fieldName]: Utils.getDivide(row[gridColumn.cols[0]], denomvalue) }
          , ...(gridColumn.agg === "percent" && !(gridColumn.cols && gridColumn.cols.length > 1)) && { [fieldName]: '' }
          , ...(gridColumn.agg === "aggDiv" && gridColumn.numcols && gridColumn.numcols.length > 0 && gridColumn.denomcols && gridColumn.denomcols.length > 0) && { [fieldName]: Utils.createValueObject2(0, 0, Utils.getAggDiv([row], gridColumn)) }
          , ...(gridColumn.agg === "aggDiv" && !(gridColumn.numcols && gridColumn.numcols.length > 0 && gridColumn.denomcols && gridColumn.denomcols.length > 0)) && { [fieldName]: Utils.createValueObject2(0, 0, '') }
          , ...(gridColumn.agg === "aggDivIf" && !Utils.doesMatchFilter(row, filterList)) && { [fieldName + '3']: 0 }
          , ...(gridColumn.agg === "aggDivIf" && Utils.doesMatchFilter(row, filterList)) && { [fieldName + '3']: 1 }
          , ...(gridColumn.agg === "dominant" && (gridColumn.cols && gridColumn.cols[0] && row[gridColumn.cols[0]])) && { [fieldName]: Utils.createValueObject2(row[gridColumn.cols[0]], row[gridColumn.cols[1]], row[gridColumn.cols[0]]) }
          , ...(gridColumn.agg === "dominant" && !(gridColumn.cols && gridColumn.cols[0] && row[gridColumn.cols[0]])) && { [fieldName]: Utils.createValueObject2(null, null, '') }
          , ...(gridColumn.agg === "count" && (gridColumn.cols && gridColumn.cols[0] && row[gridColumn.cols[0]] && gridColumn.cols[1] && row[gridColumn.cols[1]])) && { [fieldName + '4']: row[gridColumn.cols[1]] !== 0 ? 1 : 0 }
          , ...(gridColumn.agg === "count" && !(gridColumn.cols && gridColumn.cols[0] && row[gridColumn.cols[0]] && gridColumn.cols[1] && row[gridColumn.cols[1]])) && { [fieldName + '4']: 0 }
        };
        //newRow[GridIdentityColumn] = row_id;
        return newRow;

      });
    });

    const sortColumn = this.props.sortColumn;
    if(sortColumn) {
      columnList = columnList.sort((column1, column2) => (column1.displayIndex > column2.displayIndex) ? 1 : -1);
    }

    var groupColumnList = Utils.getColumnList(columnList);
    var autoGroupColumnDef = torGridBALService.getAutoGroupColumnDef(this.state.GridId);

    

    this.setState({
      rowData: rowData
      , isOpenGrid: true
      , isOpenLoad: false
      , display: "none"
      ,...(this.props.sortColumn !== true || (this.props.sortColumn && rowData?.length > 0)) && {GridColumnList: groupColumnList, GridRawcolumnList: columnList, GridGroupColumnDef: autoGroupColumnDef}
      , SnPRatingMap: SnPRatingMap
      , SnPRatingMapReverse: SnPRatingMapReverse
    });

    //var intervalId;
    setInterval(this.intervalFunc, 1000);
    return columnList;
  }


  showError(error) {
    this.setState({
      isOpenGrid: false,
      isOpenLoad: false
    });
    this.openErrorBox(error);
    console.log(error);
  }

  intervalFunc() {
    if (this.state.GridRowsToUpdate && this.state.GridRowsToUpdate.length > 0) {
      this.gridApi.batchUpdateRowData({ update: this.state.GridRowsToUpdate });
      this.setState({ GridRowsToUpdate: [] });
    }
  }


  handleCloseErrorBox = () => {
    this.setState({ display: "none" });
  }

  handleChangeAsOfDate = date => {
    this.setState({ AsOfDate: date });
  };

  handleDropDownChangeFrequency = (event, data) => {
    const Frequency = data.value
    this.setState({
      Frequency: Frequency
    });
  };



  handleDropDownChangeGridType = (event, data) => {
    this.setState({
      isLoading: true
      , isOpenGrid: false
      , GridTypeSelected: data.value
    }, async () => {
      var isGlobal = false;
      var isRemoveDisplay = false;

      const GridTypeSelectedTemp = data.value;

      var index = this.state.GridTypeList.findIndex(item => item.value === GridTypeSelectedTemp);
      if (index > -1 && this.state.GridTypeList && this.state.GridTypeList.length > index) {
        isGlobal = this.state.GridTypeList[index].isGlobal;
        isRemoveDisplay = this.state.GridTypeList[index].isRemoveDisplay;
      }

      if(data.value === 0) {
        this.setState({
          GridShowType: true
        });
      } else {
        this.setState({
          GridShowType: false
        });
      }

      await new Promise(resolve => setTimeout(resolve, 1000));

      await this.setGridLayout(index);
      

      this.setState({
        GridLayoutIsGlobal: isGlobal
        , GridLayoutRemoveDisplay: isRemoveDisplay
      }, () => {
        this.setState({
          isLoading: false
          , isOpenGrid: true
        })
      });
    });
    
    //this.gridApi.refreshClientSideRowModel('aggregate');
  };

  render() {
    return this.getPage();
  }

  onFirstDataRendered(params) {
    //Load Filter 
    //this.gridApi.refreshClientSideRowModel('aggregate');

    const { GridFilterModel, GridColumnState, GridColumnGroupState, GridIsPivotMode, GridSortModel } = params?.state ?? this;

    this.gridApi.setColumnDefs(this.state.GridColumnList);

    if (GridFilterModel) {
      this.gridApi.setFilterModel(GridFilterModel);
    }

    //Load Column State   
    if (GridColumnState) {
      this.gridColumnApi.setColumnState(GridColumnState);
      //console.log(GridColumnState);
    }

    if (GridColumnGroupState) {
      this.gridColumnApi.setColumnGroupState(GridColumnGroupState);
    }

    //Load Pivot settings
    let isPivotModeDifferent = this.gridColumnApi?.isPivotMode() !== GridIsPivotMode;

    if (isPivotModeDifferent) {
      this.gridColumnApi.setPivotMode(GridIsPivotMode);
    }

    //Load Sort
    if (GridSortModel) {
      this.gridApi.setSortModel(GridSortModel);
    }

    this.setState({ GridLoadInProgress: false, GridLayoutChanged: false });
    //this.gridApi.refreshClientSideRowModel('aggregate');
    this.gridColumnApi.autoSizeColumns();
    //this.gridColumnApi.sizeColumnsToFit();
  }

  setGridLayout = async (index) => {
    if (index > -1 && this.state.GridTypeList.length > index) {
       var gridLayout = this.state.GridTypeList[index];

      const GridFilterModel = gridLayout.filterModel ? JSON.parse(gridLayout.filterModel) : '';
      const GridColumnState = gridLayout.columnState ? JSON.parse(gridLayout.columnState) : '';
      const GridColumnGroupState = gridLayout.columnGroupState ? JSON.parse(gridLayout.columnGroupState) : '';
      const GridIsPivotMode = gridLayout.isPivotMode ? gridLayout.isPivotMode : '';
      const GridSortModel = gridLayout.sortModel ? JSON.parse(gridLayout.sortModel) : '';

      //this.debounceSaveGridColumnState(GridColumnState);
      //this.debounceSaveGridColumnGroupState(GridColumnGroupState);
      //this.saveGridSortModel(GridSortModel);
      //this.saveGridFilterModel(GridFilterModel);
      //this.debounceSaveGridPivotModeState(GridIsPivotMode);
      await this.onFirstDataRendered({
        state: {
          GridFilterModel: GridFilterModel
          , GridColumnState: GridColumnState
          , GridColumnGroupState: GridColumnGroupState
          , GridIsPivotMode: GridIsPivotMode
          , GridSortModel: GridSortModel
        }
      });

      var columnList = this.state.GridRawcolumnList.map(column => {
        return {
          ...column,
          rowGroup: column.rowGroup || (column.col && column.col !== '' && column.col === this.props.groupBy) || (column.col && column.col !== '' && this.props.groupColumns && this.props.groupColumns.includes(column.col))
          , rowGroupIndex: (column.col && this.props.groupColumns && this.props.groupColumns.includes(column.col)) ? this.props.groupColumns.indexOf(column.col) : -1
        }
      });
      var groupColumnList = Utils.getColumnList(columnList);
      this.setState({ GridColumnList: groupColumnList });
      this.refresh();
    }
    //this.gridApi.setRowData(this.state.rowData);
    //this.gridApi.refreshClientSideRowModel('aggregate');
  }

  getGridLayout(gridLayoutType) {
    this.setState({ GridLoadInProgress: true, GridLayoutChanged: false });
    const userId = userBALService.getUserInfo()?.user?.id;
    gridLayoutBALService.getAllGridLayout(this.state.GridId).then(data => {
      if (data) {
        let gridLayoutList = data.map(gridLayout => {
          return {
            ...gridLayout
            , key: gridLayout.gridLayoutId
            , text: gridLayout.type
            , value: gridLayout.gridLayoutId
            , isRemoveDisplay: gridLayout.userId === userId ? "inline" : "none"
          }
        }
        );

        //var gridLayoutListstr = JSON.stringify(gridLayoutList);

        let newGridLayout = {
          key: 0, text: "Add New", value: 0,
          gridLayoutId: 0, userId: 0, id: 0, type: "Add New", sortModel: null, filterModel: null, columnState: null, columnGroupState: null, isPivotMode: false, isGlobal: false, isRemoveDisplay: "none"
        };

        var gridLayoutListFinal = [newGridLayout].concat(gridLayoutList);
        var index = gridLayoutListFinal.findIndex(item => item.text === gridLayoutType);

        if(index < 0 && this.props.GridTypeSelected) {
          index = gridLayoutListFinal.findIndex(item => item.text === this.props.GridTypeSelected);;
        }
        else if (index < 0)
          index = 0;

        const GridLayoutIsGlobal = gridLayoutListFinal[index].isGlobal;
        const GridTypeSelected = gridLayoutListFinal[index].gridLayoutId;
        const GridShowType = ((gridLayoutListFinal && gridLayoutListFinal.length < 2) || index === 0);

        this.setState({
          GridTypeList: gridLayoutListFinal
          , GridLayoutIsGlobal: GridLayoutIsGlobal
          , GridTypeSelected: GridTypeSelected
          , GridShowType: GridShowType
        }, () => {
          this.setGridLayout(index);
        });
        
      }
    }, error => {
      this.openErrorBox(error);
      console.log(error);
    }
    );
  }

  autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  handleCheckGridLayoutIsGlobal(e) {
    const { checked } = e.target;
    this.setState({
      GridLayoutIsGlobal: checked
    });
  }

  getRowHeight(params) {
    return params?.data?.rowHeight ?? "auto";
  }
  onGridReady(params) {
    this.gridApi = params.api;
    if (this.props.setAgGridAPI)
      this.props.setAgGridAPI(params.api);
    this.gridColumnApi = params.columnApi;

    params.api.gridPanel.eAllCellContainers.forEach(
      function (container) {
           container.addEventListener('keydown', keyDownFunc);
    });
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    //this.gridColumnApi.autoSizeColumns(allColumnIds, false);

    // When reloading the page and getting the state from local storage, fetch again the data for the currently displayed grid

    if (this.state.GridFetchAgain) {
      this.getGridLayout(null);
      this.setState({
        GridFetchAgain: false
      });
    }
  }

  getContextMenuItems(params) {
    var result = [
      {
        name: 'Export',
        action: this.exportDataAsExcel,
      },
      'separator',
      'copy'
    ]

    return result;
  }

  shouldRowBeSkipped(params) {
    if(params.node.level === 0) {
      return false;
    } else {
      return ! params.node.parent?.expanded;
    }
  }

  rowGroupCallback(params) {
    return params.node.key;
  }

  exportDataAsExcel() {
    this.gridApi.exportDataAsExcel({
      shouldRowBeSkipped: this.shouldRowBeSkipped, 
      columnGroups: true,
      processRowGroupCallback: this.rowGroupCallback
    });
  }

  addNew() {
    //const { rowData } = this.state;
    const { identitySeed, gridIdentityColumn} = this.props;
    const { rowsToDisplay }  = this.gridApi.rowModel 

    var maxvalue = Math.max.apply(Math, rowsToDisplay.filter(p => p[gridIdentityColumn] < 0 ).map(function(o) { return o[gridIdentityColumn]; }));

    if(maxvalue < identitySeed)
      maxvalue = identitySeed;

    let identityvalue = (maxvalue ?? 0) + 1;
    if (identityvalue < (identitySeed ?? 0)) {
      identityvalue = identityvalue + identitySeed;
    }

    let newItem = { [gridIdentityColumn]: identityvalue, isNew :true };
    this.gridApi.updateRowData({add: [newItem]});
  }

  getPage() {
    const { MessageBoxBackground, GridGroupColumnDef, detailCellRendererParams, components, GridTitle, rowData, GridIdentityColumn, GridTypeName, GridLayoutIsGlobal, error, isOpenGrid, display, GridTypeList, GridTypeSelected, GridColumnList, frameworkComponents, sideBar, GridModules, GridShowType, isOpenLoad, isLoading } = this.state;
    return (
      <div className={rowData.length > 1 ? this.props.className ?? "ag-theme-alpine" : this.props.className + " nototal" ?? "ag-theme-alpine"} style={this.props.className === "ag-theme-alpine" ? { gridTemplateRows: "auto 1fr auto", display: "grid", height: "100%" } : null}>
        <div className="tor-grid-header">
          <div className="tor-grid-header-text" style={{ display: (GridTitle && GridTitle !== '') ? "inline" : "none" }}>
            <b>{GridTitle}</b></div>
          <div style={{ float: "left", display: display, background: { MessageBoxBackground } }} className="alert alert-danger alert-dismissible fade show" role="alert">
            <span>{error}</span>
            <span className="close" data-dismiss="alert" aria-label="Close" style={{ cursor: "pointer" }} onClick={this.handleCloseErrorBox}>
              <span aria-hidden="true">&times;</span>
            </span>
          </div>
        </div>

        <Collapse isOpen={this.props.isShowGrid ?? isOpenGrid} style={this.props.className === "ag-theme-alpine" ? { height: "100%", position: "relative" } : null}>
          <div style={this.props.className === "ag-theme-alpine" ? { gridTemplateRows: "auto 1fr", display: "grid", height: "100%" } : null}>
            <div className="row" style={{ height: "100%", paddingBottom: '10px', paddingLeft: '10px', paddingTop: "10px", display: "inline-block" }} >
              {!this.state.isHideSaveGridLayout ?
                <div style={{ float: "left"}}>
                  <div style={{ float: "left", padding: "10px 10px 10px 10px", cursor: "pointer" }}>
                    Column Ordering:
                  </div>
                  <div style={{ float: "left", padding: "0px 0px 0px 10px" }}>
                    <Dropdown
                      placeholder='Select View Name'
                      name="ddViewName"
                      fluid
                      search
                      selection
                      height={10}
                      onChange={this.handleDropDownChangeGridType.bind(this)}
                      options={GridTypeList}
                      value={GridTypeSelected}
                      className={'form-control'} style={{ height: '25px', width: '300px' }} />
                  </div>
                  <div style={{ float: "left", padding: "0px 0px 0px 10px" }}>
                    <input type="text"
                      placeholder="New Column Order View Name"
                      name="txtGridType"
                      value={GridTypeName}
                      onChange={this.handleChangeGridText}
                      autoComplete="off"
                      className={'form-control'}
                      style={GridShowType === true ? { height: '35px', width: '300px' } : { display: 'none' }} />
                  </div>

                  <div style={{ float: "left", padding: "10px 0px 0px 10px" }}>
                    &nbsp;View Is Public&nbsp; <input style={{ cursor: "pointer" }} type="checkbox" name="GridLayoutIsGlobal" onChange={this.handleCheckGridLayoutIsGlobal} checked={GridLayoutIsGlobal} />
                  </div>
                  <div style={{ float: "left", padding: "10px 50px 0px 20px" }}><FontAwesomeIcon style={{ cursor: "pointer" }} title="Save Grid Layout" onClick={this.saveGridLayout} tooltip="true" icon={faSave} />
                  </div>
                  <div style={{ float: "left", padding: "10px 0px 0px 0px" }}><FontAwesomeIcon style={{ cursor: "pointer", display: this.state.GridLayoutRemoveDisplay }} onClick={this.removeGridLayout} title="Remove Grid Layout" tooltip="true" icon={faTrash} />
                  </div>
                </div>
                : null}
                {/* <div style={{ float: "right"}}>
                  <button key='exportBtn' className="blueB-no-margin" onClick={this.exportDataAsExcel}>Export</button>
                </div> */
                } {
                  this.props.showAdd &&
                    <div style={{ float: "right"}}>
                      <button key='addButton' className="blueB-no-margin" onClick={this.addNew}>Add New Row</button>
                    </div>
                } {
                  this.props.isApprover && this.props.showReject &&
                    <div style={{ float: "right"}}>
                      <button key='rejectButton' className="blueB-no-margin" onClick={this.props.reject}>Reject</button>
                    </div>
                } {
                  this.props.isApprover && this.props.approve &&
                    <div style={{ float: "right"}}>
                      <button key='approveButton' className="blueB-no-margin" onClick={this.props.approve}>Approve</button>
                    </div>
                } {
                  this.props.showSave && this.props.isSaving && 
                    <div style={{ float: "right"}}>
                      <button key='savingButton' className="blueB-no-margin">Saving...</button>
                    </div>
                } {
                  this.props.showSave && this.props.save && !this.props.isSaving && 
                    <div style={{ float: "right"}}>
                      <button key='saveButton' className="blueB-no-margin" onClick={() => this.props.save(this.gridApi.rowModel.rowsToDisplay)}>Save</button>
                    </div>
                } {
                  this.props.showSaveAndSend && this.props.saveAndSend &&
                    <div style={{ float: "right"}}>
                      <button key='saveAndSendButton' className="blueB-no-margin" onClick={this.props.saveAndSend}>Save and Send for Approval</button>
                    </div>
                } {
                  this.props.genReport && this.props.emailReport &&
                    <div style={{ float: "right"}}>
                      <button key='saveButton' className="blueB-no-margin" onClick={this.props.emailReport}>Generate Report</button>
                    </div>
                } {
                  this.props.isPanelCollapsed && this.props.togglePanel &&
                    <div style={{ float: "right"}}>
                      <button key='saveButton' className="blueB-no-margin" onClick={this.props.togglePanel}>Show Charts</button>
                    </div>
                }
            </div>
            <div style={this.state.GridStyle} >
              <AgGridReact
                groupIncludeTotalFooter={rowData.length > 1 ? this.props.groupIncludeTotalFooter : false}
                groupIncludeFooter={rowData.length > 1 ? this.props.groupIncludeFooter : false}
                sideBar={this.props.sideBar ?? sideBar}
                suppressExpandablePivotGroups={this.props.suppressExpandablePivotGroups}
                modules={GridModules}
                onCellClicked={this.props.onCellClicked}
                suppressDragLeaveHidesColumns={true}
                suppressMakeColumnVisibleAfterUnGroup={true}
                suppressAggFuncInHeader={true}
                rowGroupPanelShow={this.props.rowGroupPanelShow ?? "always"}
                rowSelection={this.props.rowSelection}
                groupSelectsChildren={this.props.groupSelectsChildren ?? false}
                ref="agGrid"
                frameworkComponents={frameworkComponents}
                components={components}
                rowMultiSelectWithClick = {this.props.rowMultiSelectWithClick ?? false}
                rowData={rowData}
                getRowNodeId={(data) => { return data[GridIdentityColumn]; }}
                enableFilter={this.props.enableFilter ?? true}
                domLayout={this.props.domLayout}
                groupDefaultExpanded={this.props.groupDefaultExpanded ?? 0}
                isRowSelectable={this.props.isRowSelectable}
                debounceVerticalScrollbar={true}
                pagination={this.props.pagination ?? false}
                paginationPageSize={this.props.paginationPageSize ?? 10}                
                singleClickEdit = {this.props.singleClickEdit ?? true }
                defaultColDef={this.props.defaultColDef ??
                {
                  sortable: true
                  , filter: true
                  , floatingFilter: true
                  , enableRowGroup: true
                  , resizable: true
                }
                }

                columnDefs={GridColumnList}
                autoGroupColumnDef={GridGroupColumnDef}
                getContextMenuItems={this.getContextMenuItems}
                allowContextMenuWithControlKey={true}
                excelStyles={[
                  {
                    id: 'indent-1',
                    alignment: { indent: 2 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-2',
                    alignment: { indent: 4 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-3',
                    alignment: { indent: 6 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-4',
                    alignment: { indent: 8 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-5',
                    alignment: { indent: 10 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-6',
                    alignment: { indent: 12 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-7',
                    alignment: { indent: 14 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-8',
                    alignment: { indent: 16 },
                    dataType: 'string',
                  },
                  {
                    id: 'indent-9',
                    alignment: { indent: 18 },
                    dataType: 'string',
                  },
                ]}

                onGridReady={this.onGridReady.bind(this)}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                onSelectionChanged = {this.props.rowSelected}
                onCellValueChanged = {this.props.cellValueChanged}
                masterDetail={this.props.masterDetail}
                detailCellRendererParams={ detailCellRendererParams }  
                getRowHeight= {this.getRowHeight.bind(this)}
                allowDragFromColumnsToolPanel={true}
              //onSortChanged={this.onSortChanged.bind(this)}
              //onFilterChanged={this.onFilterChanged.bind(this)}
              //onColumnVisible={this.onSaveGridColumnState.bind(this)}
              //onColumnPinned={this.onSaveGridColumnState.bind(this)}
              //onColumnResized={this.onSaveGridColumnState.bind(this)}
              //onColumnMoved={this.onSaveGridColumnState.bind(this)}
              //onColumnRowGroupChanged={this.onSaveGridColumnState.bind(this)}
              //onColumnValueChanged={this.onSaveGridColumnState.bind(this)} // A value column was added or removed.
              //onColumnPivotModeChanged={this.onSavePivotModeState.bind(this)} // A pivot column was added, removed or order changed.
              >
              </AgGridReact>
            </div>
          </div>
        </Collapse>
        <Collapse isOpen={this.props.isShowLoad ?? (isOpenLoad || isLoading)}  >
          <div style={{ padding: "5px 0px 8px 0px", position: "relative", borderTop: "solid 1px white", height: "100%" }}>
            <img src={require('../../images/Loading.gif')} alt="Loading..." />
          </div>
        </Collapse>
      </div>
    );
  }  /**</getPage>*************************************************************************************************/
} /**</class Deals>***************************************************************************************/

function getDatePicker(dateFormat) {
  function Datepicker() {}
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    this.eInput.classList.add('ag-input');
    this.eInput.style.height = '100%';
    $(this.eInput).datepicker({ dateFormat: dateFormat ?? 'dd/mm/yy' });
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Datepicker.prototype.destroy = () => {};
  Datepicker.prototype.isPopup = () => {
    return false;
  };
  return Datepicker;
}

function keyDownFunc(e) {
  
  if (e.keyCode === 13) { // enter = 13
     // TODO: Handle event
  }
}
export default TorGrid;
